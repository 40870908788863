<template>

    <v-container class="pa-0 white" fluid>

        <v-card class="brown lighten-1 white--text pa-0 ma-0 elevation-0" dense style="border-radius: 0px">
            <v-card-title class="pt-1 pb-1 overline"> {{ $t('benchmark') }}</v-card-title>
        </v-card>

        <v-card class="pa-2" outlined>
            {{ $t("selezionare_azienda") }}

            <v-autocomplete
                item-text="name"
                item-value="id"
                v-model="company_id"
                :items="list_companies"
                color="blue"
                :label="$t('elenco_aziende')"
                @change="companySelected"
            ></v-autocomplete>

        </v-card>

        <v-card class="pa-2" outlined v-if="company">

            <v-card-text class="pb-0 mb-0">
                <v-col class="col-4">
                    <h2> {{ $t('benchmark') }}: {{ company.name }}</h2>
                </v-col>
            </v-card-text>

            <v-col class="col-12 ma-1 pt-0">
                <v-card class="pa-12 pt-1">
                    <ApexRadarSingle
                        :key="graph_key"
                        :chart-data="graph_data"
                        :label-size="100"
                        :big-labels="true"
                        :options="radarChartOptionsSmall"></ApexRadarSingle>
                </v-card>
            </v-col>

            <v-card-text class="pb-0 mb-0">
                <v-row>
                    <v-col class="col-3">

                        <v-autocomplete
                            :item-text="atecoLabel"
                            :multiple="true"
                            v-model="current_filters.settori"
                            :items="ateco"
                            color="blue"
                            :label="$t('filtro_settore') + ' (ATECO)'"
                        ></v-autocomplete>

                    </v-col>

                    <v-col class="col-3">

                        <v-autocomplete
                            :item-text="naceLabel"
                            v-model="current_filters.settori"
                            :items="ateco"
                            color="blue"
                            :label="$t('filtro_settore') + ' (NACE)'"
                        ></v-autocomplete>

                    </v-col>

                    <v-col class="col-2">

                        <v-autocomplete
                            v-model="current_filters.numero_dipendenti"
                            :items="numero_dipendenti"
                            color="blue"
                            :label="$t('filtro_dipendenti')"
                        ></v-autocomplete>

                    </v-col>

                    <v-col class="col-2">

                        <v-autocomplete
                            v-model="current_filters.fatturato"
                            :items="fatturato"
                            color="blue"
                            :label="$t('filtro_fatturato')"
                        ></v-autocomplete>

                    </v-col>

                    <v-col class="col-2">

                        <v-autocomplete
                            v-model="current_filters.anno_di_riferimento"
                            :items="anno_di_riferimento"
                            color="blue"
                            :label="$t('filtro_anno_assessment')"
                        ></v-autocomplete>

                    </v-col>

                </v-row>
            </v-card-text>

            <v-card-text class="pb-0 mb-0">
                <v-row>

                    <v-col class="col-2">

                        <v-autocomplete
                            v-model="current_filters.nazioni"
                            :items="nazioni"
                            multiple
                            color="blue"
                            :label="$t('nazioni').toUpperCase()"
                        ></v-autocomplete>

                    </v-col>

                    <v-col class="col-3">

                        <v-autocomplete
                            v-model="current_filters.regioni"
                            :items="regioni"
                            multiple
                            color="blue"
                            :label="$t('regioni')"
                        ></v-autocomplete>

                    </v-col>

                    <v-col class="col-5">

                        <v-autocomplete
                            v-model="current_filters.province"
                            :items="province"
                            multiple
                            color="blue"
                            :label="$t('province')"
                        ></v-autocomplete>

                    </v-col>

                    <v-col class="col-2">

                        <v-btn @click="loadData" color="primary">
                            {{ $t("aggiorna") }}
                        </v-btn>

                    </v-col>


                </v-row>
            </v-card-text>

            <v-simple-table v-if="aggregatedData">
                <template v-slot:default>
                    <tbody>
                        <tr>
                            <td class="text-left">
                                {{ $t('numero_aziende').toUpperCase() }}
                            </td>
                            <td  style="padding: 20px">
                                {{aggregatedData.numeroAziende}}
                            </td>
                        </tr>
                        <tr>
                            <td class="text-left">
                                {{ $t('codici_ateco').toUpperCase() }} ({{ company.country === "IT" ? "ATECO" : "NACE" }})
                            </td>
                            <td style="padding: 20px" v-html="aggregateList(aggregatedData.settori, 'settori')"></td>
                        </tr>
                        <tr>
                            <td class="text-left">
                                {{ $t('filtro_dipendenti').toUpperCase() }}
                            </td>
                            <td style="padding: 20px" v-html="aggregateList(aggregatedData.dipendenti, 'dipendenti')"></td>
                        </tr>
                        <tr>
                            <td class="text-left">
                                {{ $t('filtro_fatturato').toUpperCase() }}
                            </td>
                            <td style="padding: 20px" v-html="aggregateList(aggregatedData.fatturato, 'fatturato')"></td>
                        </tr>
                        <tr>
                            <td class="text-left">
                                {{ $t('nazioni').toUpperCase() }}
                            </td>
                            <td style="padding: 20px" v-html="aggregateList(aggregatedData.nazioni, 'nazioni')"></td>
                        </tr>
                        <tr>
                            <td class="text-left">
                                {{ $t('regioni').toUpperCase() }}
                            </td>
                            <td style="padding: 20px" v-html="aggregateList(aggregatedData.regioni, 'regioni')"></td>
                        </tr>
                        <tr>
                            <td class="text-left">
                                {{ $t('province').toUpperCase() }}
                            </td>
                            <td style="padding: 20px" v-html="aggregateList(aggregatedData.province, 'province')"></td>
                        </tr>
                        <tr>
                            <td class="text-left">
                                {{ $t('anni').toUpperCase() }}
                            </td>
                            <td style="padding: 20px" v-html="aggregateList(aggregatedData.anni, 'anni')"></td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <br />&nbsp;<br />

            <br />&nbsp;<br />

        </v-card>
    </v-container>
</template>

<script>

import ApexRadarSingle from '@/components/ApexChartRadarSingle.vue'
import graph_calcs from "@/helpers/graph_calcs";
import ateco_nace from '../ateco_nace.json';
import countries from '../countries.json';
import {
    getCountryFromRegion, getCountryNameFromCode,
    getEuropeanCountries, getProvinceNameFromCode,
    getProvincesForCountryAndRegion, getRegionNameFromCode,
    getRegionsForCountry
} from "@/helpers/country_data_utils";

export default {
    name:       'Benchmark',
    components: {ApexRadarSingle},
    data:       function () {
        return {

            company:        null,
            company_id:     null,
            list_companies: [],

            current_filters: {
                settori:             [],
                numero_dipendenti:   "0",
                fatturato:           "0",
                nazioni:             [],
                regioni:             [],
                province:           [],
                anno_di_riferimento: "0",
            },

            numero_dipendenti: [
                {text: this.$t("tutti"), value: "0"},
                {text: "0-5", value: "0_6"},
                {text: "6-9", value: "6_10"},
                {text: "10-19", value: "10_20"},
                {text: "20-49", value: "20_50"},
                {text: "50-99", value: "50_100"},
                {text: "100-249", value: "100_250"},
                {text: ">= 250", value: "250_1000000"},
            ],

            fatturato: [
                {text: this.$t("tutti"), value: "0"},
                {text: "fino a 500.000 €", value: "0_500000"},
                {text: "da 500.000 a 2 milioni di €", value: "500000_2000000"},
                {text: "da 2 a 5 milioni di €", value: "2000000_5000000"},
                {text: "da 5 a 10 milioni di €", value: "5000000_10000000"},
                {text: "da 10 a 25 milioni di €", value: "10000000_25000000"},
                {text: "da 25 a 50 milioni di €", value: "25000000_50000000"},
                {text: "più di 50 milioni di €", value: "50000000_10000000000"},
            ],

            anno_di_riferimento: [
                {value: "0", text: this.$t("tutti")},
                {value: "in_corso", text: this.$t("anno_in_corso")},
                {value: "precedente", text: this.$t("anno_precedente")},
                {value: "due_precedenti", text: this.$t("due_anni_precedenti")},
            ],

            ateco: [{
                text:       this.$t("tutti"),
                nace_value: "xxx",
                value:      "xxx"
            }].concat(ateco_nace),

            audit: null,

            marketCompanies: [],
            aggregatedData: null,

            selectedAudits: [],

            // Charts
            radarChartOptionsSmall: {
                legend: {
                    display: true
                },
            },

            graph_key: 0
        }
    },
    mounted() {
        this.loadCompanies();
    },
    computed: {
        graph_data: function () {

            // Calcoliamo il dato per la company selezionata

            if (!this.audit) {
                return {labels: [], datasets: []};
            }

            const {dd, radarDataForAudit} = graph_calcs(this.audit);
            radarDataForAudit.datasets.push({
                label:           this.company.name,
                data:            dd,
                borderColor:     '#1976d2',
                backgroundColor: '#1976d2',
            })

            // Calcoliamo la media delle altre aziende
            let dd_medio = null;
            for (let i = 0; i < this.marketCompanies.length; i++) {
                let ca = this.marketCompanies[i].audit;

                const {dd} = graph_calcs(ca);

                if (!dd_medio) {
                    dd_medio = dd;
                } else {
                    for (let j = 0; j < dd.length; j++) {
                        dd_medio[j] += dd[j];
                    }
                }

            }

            if (!dd_medio) {
                return {labels: [], datasets: []};
            }
            dd_medio = dd_medio.map((value) => {
                return value / this.marketCompanies.length;
            });

            radarDataForAudit.datasets.push({
                label:           this.$t("media_benchmark"),
                data:            dd_medio,
                borderColor:     '#66BB6A',
                backgroundColor: '#66BB6A',
            });

            return radarDataForAudit;

        },

        nazioni: function () {
            return getEuropeanCountries();
        },

        regioni:  function () {
            // Regions nel JSON sono le province
            let regioni = [];

            this.current_filters.nazioni.forEach((selectedCountry) => {
                let country_regions = getRegionsForCountry(selectedCountry);
                regioni = regioni.concat(country_regions);
            });

            regioni = regioni.sort((a, b) => {
                return a.text.localeCompare(b.text);
            });

            return regioni;
        },

        province: function () {

            let province = [];

            this.current_filters.regioni.forEach((selectedRegion) => {
                let country = getCountryFromRegion(selectedRegion);
                let region_provinces = getProvincesForCountryAndRegion(country.countryShortCode, selectedRegion);
                province = province.concat(region_provinces);
            })

            province = province.sort((a, b) => {
                return a.text.localeCompare(b.text);
            });

            return province;
        }

    },
    methods:  {
        getProvinceNameFromCode,
        getRegionNameFromCode,
        getCountryNameFromCode,
        loadData() {
            this.$store.dispatch('benchmark_data', {
                body: {
                    filters: this.current_filters,
                    company: this.company,
                },
                cb:   (err, response) => {

                    let result = response.data.response;

                    if(response.data.err){
                        alert(response.data.err);
                        return;
                    }

                    console.log(result, "result");

                    this.marketCompanies = result.marketCompanies;
                    this.aggregatedData = result.aggregatedData;
                    this.selectedAudits = this.marketCompanies.map((company) => {
                        return company.audit;
                    });

                    this.audit = result.currentCompanyAudit;

                    this.graph_key++;
                }
            })
        },
        loadCompanies() {
            this.$store.dispatch('get', {
                kind: 'company',
                data: {
                    workspace: this.$store.state.workspace,
                },
                cb:   (err, data) => {

                    if (err == null) {

                        this.list_companies = data.response;

                    }

                }
            })
        },
        atecoLabel: function (item) {
            if (item.value === "xxx") {
                return item.text;
            }
            return item.value + " - " + item.ateco_text;
        },
        naceLabel:  function (item) {
            if (item.value === "xxx") {
                return item.text;
            }
            return item.nace_value + " - " + item.nace_text;
        },
        companySelected() {

            this.company = this.list_companies.find((c) => {
                return c.id === this.company_id;
            });

            this.loadData();
        },
        aggregateList: function(list, listName){

            let output = [];
            if(listName === "settori"){

                let new_list = [];
                ateco_nace.forEach((item) => {
                    if(list.includes(item.nace_value)){

                        let code_text = null;
                        if(this.company.country === "IT"){
                            code_text = item.ateco_text;
                        }else{
                            code_text = item.nace_text;
                        }

                        new_list.push(item.value + " - " + code_text);
                    }
                });
                output = new_list;

            }else if (listName === "dipendenti"){

                let new_list = [];
                this.numero_dipendenti.forEach((item) => {
                    if(list.includes(item.value)){
                        new_list.push(item.text);
                    }
                });
                output = new_list;

            }else if (listName === "fatturato"){

                let new_list = [];
                this.fatturato.forEach((item) => {
                    if(list.includes(item.value)){
                        new_list.push(item.text);
                    }
                });
                output = new_list;

            }else if (listName === "nazioni"){

                let new_list = [];
                countries.forEach((country) => {
                    if(list.includes(country.countryShortCode)){
                        new_list.push(country.countryName);
                    }
                });
                output = new_list;

            }else if (listName === "regioni"){

                list = list.filter((item) => {
                    if(!item) return false;
                    let test = item + "";
                    return test.length > 0;
                });
                list = list.sort();
                output = list;

            }else if (listName === "province"){

                list = list.filter((item) => {
                    if(!item) return false;
                    let test = item + "";
                    return test.length > 0;
                });
                list = list.sort();
                output = list;

            }else if (listName === "anni"){

                list = list.filter((item) => {
                    if(!item) return false;
                    let test = item + "";
                    return test.length > 0;
                });
                list = list.sort();
                output = list;

            }

            let result = "<ul>";
            output.forEach((item) => {
                result += "<li>" + item + "</li>";
            });
            result += "</ul>";
            return result;

        },

        aggregateRange: function(list){
            list = list.filter((item) => {
                if(!item) return false;
                let test = item + "";
                return test.length > 0;
            });

            const nFormat = new Intl.NumberFormat();
            list = list.map((item) => {
                if(item[0] === ">"){
                    const parts = item.split(" ");
                    return "> " + nFormat.format(parts[1]);
                }else{
                    const parts = item.split("_");
                    return nFormat.format(parts[0]) + " - " + nFormat.format(parts[1]);
                }

            })

            list = list.sort();
            return list.join(", ");
        },

        formatNumber: function (value) {
            return new Intl.NumberFormat('it-IT', {
                style:                 'currency',
                currency:              'EUR',
                maximumFractionDigits: 0
            }).format(value);
        }
    }
}
</script>

<style>
.apexcharts-legend-text {
    font-size: 16px !important;
}

thead th {
    font-size: 16px !important;
}
</style>
