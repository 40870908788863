import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueI18n from 'vue-i18n'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import lang_it from './langs/it';
import lang_en from './langs/en';
import lang_de from './langs/de';

Vue.component('VueSlider', VueSlider)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    function verifyCookie(cb) {
        let cookie    = Vue.prototype.$cookie
        let userToken = cookie.get('theroadmap.userToken')

        if (userToken !== undefined && userToken !== 'null' && userToken !== null) {
            store.commit('user', {
                isAuth:    true,
                userToken: userToken,
                username:  cookie.get('theroadmap.username')
            })
            cb(null)
        } else {
            store.commit('user', {
                isAuth:    false,
                userToken: null,
                username:  ''
            })
            cb(true)
        }
    }

    if (store.state.isAuth == false && to.name == 'Login') {
        verifyCookie((err) => {
            if (err !== null) {
                next()
            } else {
                router.push('/')
            }
        })

    } else if (store.state.isAuth == false && to.name != 'Login') {
        verifyCookie((err) => {
            if (err !== null) {
                router.push('/login')
            } else {
                next()
            }
        })
    } else if (store.state.isAuth == true) {
        next()

    }
});

// Just for testing
// for (let key in lang_it) {
//     lang_it[key] = "@"+lang_it[key];
// }

const messages = {
    it: lang_it,
    en: lang_en,
    de: lang_de,
}

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'it', // set locale
    fallbackLocale: 'it',
    messages,
});

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')
