import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Match from '../views/Match.vue'
import ExplorerCard from '../views/ExplorerCard.vue'
import ManageCompany from '../views/ManageCompany.vue'
import ManageAudit from '../views/ManageAudit.vue'
import ManageAuditTemplate from '../views/ManageAuditTemplate.vue'
import Benchmark from "@/views/Benchmark";

Vue.use(VueRouter)

const routes = [
    {
        path:      '/login',
        name:      'Login',
        component: Login
    },
    {
        path:      '/',
        name:      'Dashboard',
        component: Dashboard
    },
    {
        path:      '/match',
        name:      'Match',
        component: Match
    },
    /*{
      path: '/explorer/:kind',
      name: 'Explorer',
      component: Explorer,
      props: true
    },*/
    {
        path:      '/explorer/:kind',
        name:      'ExplorerCard',
        component: ExplorerCard,
        props:     true
    },
    {
        path:      '/manage/company/:company',
        name:      'ManageCompany',
        component: ManageCompany,
        props:     true
    },
    {
        path:      '/manage/audittemplate/:audittemplate',
        name:      'ManageAuditTemplate',
        component: ManageAuditTemplate,
        props:     true
    },
    {
        path:      '/manage/audit/:audit',
        name:      'ManageAudit',
        component: ManageAudit,
        props:     true
    },
    {
        path:      '/benchmark',
        name:      'Benchmark',
        component: Benchmark,
        props:     true
    }
]

const router = new VueRouter({
    routes
})

export default router
