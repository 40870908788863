<template>
    <v-app>
        <v-app-bar
            v-if="$store.state.isAuth == true"

            app
            class="elevation-2 brown darken-1"
            clipped-left
            dark
            dense
        >
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="overline">
                INTERNATIONAL ASSESSMENT
            </v-toolbar-title>
            <v-spacer/>

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{$store.state.workspace_current.name}}
                    </v-btn>
                </template>
                <v-list>

                    <v-list-item v-for="workspace in $store.state.workspaces_list" @click="changeWorkspace(workspace)">
                        <v-list-item-title>
                            {{workspace.name}}
                        </v-list-item-title>
                    </v-list-item>

                </v-list>
            </v-menu>

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        text
                        v-bind="attrs"
                        v-on="on"
                    >
                        <img :src="currentFlag" :alt="$i18n.locale" class="flag-icon"/>
                    </v-btn>
                </template>
                <v-list>

                    <v-list-item @click="changeLocale('it')">
                        <v-list-item-title>
                            <img src="./assets/flag_it.png" alt="lang it" class="flag-icon"/>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="changeLocale('en')">
                        <v-list-item-title>
                            <img src="./assets/flag_en.png" alt="lang en" class="flag-icon"/>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="changeLocale('de')">
                        <v-list-item-title>
                            <img src="./assets/flag_de.png" alt="lang de" class="flag-icon"/>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="changeLocale('fr')">
                        <v-list-item-title>
                            <img src="./assets/flag_fr.png" alt="lang fr" class="flag-icon"/>
                        </v-list-item-title>
                    </v-list-item>


                </v-list>
            </v-menu>

            <div class="locale-changer">
                <select v-model="$i18n.locale">
                    <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">

                    </option>
                </select>
            </div>

        </v-app-bar>

        <v-navigation-drawer width="270" v-if="$store.state.isAuth == true && drawer == true" v-model="drawer" :mini-variant="false" app class="elevation-2 " clipped dark
                             flat permanent>

            <v-list-item class="pt-6">
                <v-list-item-content>
                    <v-list-item-title class="overline">
                        {{ $t('aziende')}}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item link v-on:click="$router.push('/explorer/company', () => {})">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item-icon>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-if="$route.path == '/explorer/company'" color="primary" v-bind="attrs"
                                            v-on="on">fas fa-building
                                    </v-icon>
                                    <v-icon v-else color="grey" v-bind="attrs" v-on="on">fas fa-building</v-icon>
                                </template>
                                <span>   {{ $t('aziende')}}</span>
                            </v-tooltip>

                        </v-list-item-icon>
                        <v-list-item-content v-on:click="$router.push('/explorer/company', () => {})">
                            <v-list-item-title>{{ $t('elenco_aziende')}}</v-list-item-title>
                            <v-list-item-subtitle>{{ $t('crea_modifica')}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <span>{{ $t('aziende')}}</span>
                </v-tooltip>
            </v-list-item>


            <v-list-item class="pt-6">
                <v-list-item-content>
                    <v-list-item-title class="overline">
                        {{$t("audit_assessment")}}
                    </v-list-item-title>
                    <!--<v-list-item-subtitle>
                      Show and insert new audits
                    </v-list-item-subtitle>-->
                </v-list-item-content>
            </v-list-item>

            <v-list-item link v-on:click="$router.push('/explorer/audit', () => {})">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item-icon>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-if="$route.path == '/explorer/audit'" color="primary" v-bind="attrs"
                                            v-on="on">far fa-file-alt
                                    </v-icon>
                                    <v-icon v-else color="grey" v-bind="attrs" v-on="on">far fa-file-alt</v-icon>
                                </template>
                                <span>{{ $t('audits')}}</span>
                            </v-tooltip>

                        </v-list-item-icon>
                        <v-list-item-content v-on:click="$router.push('/', () => {})">
                            <v-list-item-title>{{$t("elenco_audit")}}</v-list-item-title>
                            <v-list-item-subtitle>{{ $t('crea_modifica')}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <span>{{ $t('audits')}}</span>
                </v-tooltip>
            </v-list-item>


            <v-list nav>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="overline">
                            {{$t("analisi")}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <!--<v-list-item link v-on:click="$router.push('/analytics')">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-icon>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="primary" v-if="$route.path == '/analytics'">fas fa-chart-pie</v-icon>
                            <v-icon v-bind="attrs" v-on="on" color="grey" v-else>fas fa-chart-pie</v-icon>
                          </template>
                          <span>Analytics</span>
                        </v-tooltip>

                      </v-list-item-icon>
                      <v-list-item-content v-on:click="$router.push('/analytics')">
                        <v-list-item-title>Analytics</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <span>Analytics</span>
                  </v-tooltip>
                </v-list-item>-->


                <v-list-item link v-on:click="$router.push('/match', () => {})">
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item-icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-if="$route.path == '/match'" color="primary" v-bind="attrs" v-on="on">
                                            fas fa-columns
                                        </v-icon>
                                        <v-icon v-else color="grey" v-bind="attrs" v-on="on">fas fa-columns</v-icon>
                                    </template>
                                    <span>{{ $t('confronto')}}</span>
                                </v-tooltip>

                            </v-list-item-icon>
                            <v-list-item-content v-on:click="$router.push('/match', () => {})">
                                <v-list-item-title>{{ $t('confronto')}}</v-list-item-title>
                                <v-list-item-subtitle>{{ $t('confronta_audit')}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                        <span>{{ $t('confronto')}}</span>
                    </v-tooltip>
                </v-list-item>

                <v-list-item link v-on:click="$router.push('/benchmark', () => {})">
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item-icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-if="$route.path == '/benchmark'" color="primary" v-bind="attrs" v-on="on">
                                            fas fa-chart-line
                                        </v-icon>
                                        <v-icon v-else color="grey" v-bind="attrs" v-on="on">fas fa-chart-line</v-icon>
                                    </template>
                                    <span>{{ $t('benchmark')}}</span>
                                </v-tooltip>

                            </v-list-item-icon>
                            <v-list-item-content v-on:click="$router.push('/benchmark', () => {})">
                                <v-list-item-title>{{ $t('benchmark')}}</v-list-item-title>
                                <v-list-item-subtitle v-html="$t('compara_azienda')"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                        <span>{{ $t('benchmark')}}</span>
                    </v-tooltip>
                </v-list-item>

                <div v-if="$store.state.workspace == 'trentino_sviluppo_spa_socio_unico'">
                  <v-list-item class="pt-6">
                      <v-list-item-content>
                          <v-list-item-title class="overline">
                              {{ $t('modelli')}}
                          </v-list-item-title>
                          <!--<v-list-item-subtitle>
                            Show and insert new audits
                          </v-list-item-subtitle>-->
                      </v-list-item-content>
                  </v-list-item>

                  <v-list-item link v-on:click="$router.push('/explorer/audittemplate')">
                      <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                              <v-list-item-icon>
                                  <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                          <v-icon v-if="$route.path == '/explorer/audittemplate'" color="primary" v-bind="attrs"
                                                  v-on="on">fas fa-tasks
                                          </v-icon>
                                          <v-icon v-else color="grey" v-bind="attrs" v-on="on">fas fa-tasks</v-icon>
                                      </template>
                                      <span> {{ $t('modelli')}}</span>
                                  </v-tooltip>

                              </v-list-item-icon>
                              <v-list-item-content v-on:click="$router.push('/')">
                                  <v-list-item-title>{{ $t('audit_template')}}</v-list-item-title>
                                  <v-list-item-subtitle>{{ $t('crea_modifica')}}</v-list-item-subtitle>
                              </v-list-item-content>
                          </template>
                          <span>    {{ $t('modelli')}}</span>
                      </v-tooltip>
                  </v-list-item>
                </div>

                <v-list-item v-if="$route.path.includes('/manage/audittemplate')" link>
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item-icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon class="ml-4" color="primary" small v-bind="attrs" v-on="on">fas
                                            fa-dot-circle
                                        </v-icon>
                                    </template>
                                    <span>{{ $store.state.selectedAuditTemplate }}</span>
                                </v-tooltip>

                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ $store.state.selectedAuditTemplate }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <span>{{ $store.state.selectedAuditTemplate }}</span>
                    </v-tooltip>
                </v-list-item>



            </v-list>
            <template v-slot:append >
                <div class="logo_sidebar_cnt">
                    <img alt="sponsor" src="./assets/sponsor_2.jpg" class="logo_sidebar" />
                </div>

            </template>
        </v-navigation-drawer>

        <v-main class="brown lighten-4">
            <router-view :key="$route.path"/>
        </v-main>
    </v-app>

</template>

<script>

import flag_it from './assets/flag_it.png';
import flag_en from './assets/flag_en.png';
import flag_de from './assets/flag_de.png';
import flag_fr from './assets/flag_fr.png';

export default {
    name: 'App',

    data:    () => ({
        langs: ['it', 'en', 'de', 'fr'],
        drawer: true,
    }),

    computed: {
        currentFlag(){
            switch (this.$i18n.locale) {
                case 'it':
                    return flag_it;
                case 'en':
                    return flag_en;
                case 'de':
                    return flag_de;
                case 'fr':
                    return flag_fr;
            }
        }
    },
    methods: {
        changeLocale(lang){
            this.$i18n.locale = lang;
        },
        changeWorkspace(workspace){
            this.$store.dispatch('change_workspace', {
                workspace:workspace
            });

            setTimeout(() => {
                this.$router.push('/match', () => {})
            }, 100);
        }
    }
};
</script>

<style>

.logo_sidebar_cnt{
    background-color: white;
    height: 100%;
    padding-top: 20px;
}

.logo_sidebar{
    display: block;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.v-application .v-toolbar__content  .overline{
    font-size: 14px !important;
}

.v-sheet{
    font-size: 14px;
}

.v-label{
    text-transform: none !important;
     font-size: 14px !important;
 }

.v-card__title{
    text-transform: uppercase;
}

.flag-icon{
    width: 30px;
}

.v-list-item__subtitle{
    white-space: normal !important;
}

</style>
