<template>
    <v-container class="pa-0" fluid>
        <v-card class="brown lighten-1 white--text pa-0 ma-0 elevation-0" dense style="border-radius: 0px">
            <v-card-title class="pt-1 pb-1 overline" > {{ $t("confronta_title")}}
                <v-spacer/>
                <v-btn v-if="started == true" color="white" text @click="started = false"> {{ $t('nuovo')}}</v-btn>
            </v-card-title>
        </v-card>
        <v-card v-if="started == false" outlined>
            <v-card-text>
                <v-select
                    v-model="selectedVersions"
                    :items="history"
                    :menu-props="{ maxHeight: '400' }"
                    :hint="$t('seleziona_versione_confronto')"
                    :label="$t('seleziona')"
                    multiple
                    persistent-hint
                ></v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn v-if="enable == true" color="primary" text @click="start">{{ $t("inizia_confronto")}}</v-btn>
            </v-card-actions>
        </v-card>


        <div v-if="started == true">
            <v-row class="mt-0 pt-0">

                <v-col class="col-12 ma-1 pt-0">
                    <v-card class="pa-12 pt-1">
                        <ApexRadarSingle
                            :label-size="100"
                            :big-labels="true"
                            :chart-data="computeRadar()"
                            :options="radarChartOptionsSmall"></ApexRadarSingle>
                    </v-card>
                </v-col>
                <v-col class="col-12">
                    <v-card class="pa-12">
                        <chart-bar
                            :height="100"
                            :chart-data="computeRadar()"
                            :options="barChartOptions"></chart-bar>
                    </v-card>
                </v-col>

                <div v-for="(section, indexSection) in history[0].data.data.sections"  class="col-12">
                    <v-col class="col-12">
                        <v-card class="pa-12">
                            <div class="">
                                <div class="section_name">{{ section.name }}</div>

                                <div v-for="(question, indexQuestion) in section.questions">

                                    <div class="question col-12">
                                        {{ question.question }}
                                    </div>

                                    <div class="row">
                                        <v-col :class="'col-' + Math.floor(12 / selectedVersions.length)"
                                               v-for="index in selectedVersions">

                                            {{ getQuestionAnswer(index, indexSection, indexQuestion) }}

                                        </v-col>
                                    </div>

                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </div>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import ChartRadarSingle from '@/components/ChartRadarSingle.vue'
import ChartBar from '@/components/ChartBar.vue'
import ApexRadarSingle from '@/components/ApexChartRadarSingle.vue'
import graph_calcs from "@/helpers/graph_calcs";
import {getColor} from "@/helpers/get_color";
import {DateTime} from "luxon/build/es6/luxon";

export default {
    props: ['audit'],
    name: 'HistoryCard',
    components: {
        ChartRadarSingle, ChartBar, ApexRadarSingle
    },
    data: function () {
        return {
            history: [],
            selectedVersions: [],

            enable: false,
            started: false,

            // Charts
            radarChartOptionsSmall: {
                legend: {
                    display: true
                },
                plugins: {
                    tooltip: {
                        enabled: false
                    }
                }
            },

            barChartOptions: {
                tooltips: {
                    enabled: false
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            min: 0,
                            max: 10
                        }
                    }]
                }
            },

            scores: {}

        }
    },

    mounted() {
        this.fetchAudits()
    },
    watch: {
        selectedVersions(newVal, oldVal) {
            this.enable = newVal.length > 1 ? true : false
        }
    },
    methods: {
        start() {
            this.computeRadar()
        },

        getQuestionAnswer(index, indexSection, indexQuestion) {

            let version = this.history.find((h) => {
                return h.id === index;
            });

            const question = version.data.data.sections[indexSection].questions[indexQuestion];
            if (!question) return "";
            if (question.answerKind === "text") {
                return question.answer;
            } else if (question.answerKind === "radio" || question.answerKind === "radio-specify-score") {
                const index = parseInt(question.answer, 10);
                if (question.answerOptions && Array.isArray(question.answerOptions) && question.answerOptions.length > index) {
                    return question.answerOptions[index];
                }

            } else if (question.answerKind === "multiple") {
                let result = "";
                question.answer.forEach((a) => {
                    if (a.value === true) {
                        if (result !== "") {
                            result += ", ";
                        }
                        let index = parseInt(a.id, 10);
                        result += question.answerOptions[index - 1];
                    }
                })
                return result;
            }

        },
        computeRadar() {
            let index = 0;

            let radarData = null;

            for (let i = 0; i < this.selectedVersions.length; i++) {
                const saIndex = this.selectedVersions[i];
                let version = this.history.find((h) => {
                    return h.id === saIndex;
                });


                const {score, dd, radarDataForAudit} = graph_calcs(version);
                if (i === 0) {
                    radarData = radarDataForAudit;
                }

                let versionLabel = this.formatDate(version.created_at) + ' - ' + version.user;

                radarData.datasets.push({
                    label:versionLabel,
                    data: dd,
                    borderColor: this.getColor(index),
                    backgroundColor: this.getColor(index),
                })

                this.scores[versionLabel] = score;

                index += 1;
            }

            this.started = true;
            return radarData;
        },

        getColor(index) {
            return getColor(index);
        },

        formatDate(date) {
            let dt = DateTime.fromISO(date);
            return dt.toFormat("dd/LL/yyyy HH:mm")
        },

        fetchAudits() {

            this.$store.dispatch('history', {
                body: {
                    audit_id: this.audit.id,
                },
                cb: (err, response) => {

                    let result = response.data.response;

                    this.history = result.map((version) => {
                        version.text = this.formatDate(version.created_at) + ' - ' + version.user;
                        version.value = version.id
                        version.data = JSON.parse(version.data);
                        return version;
                    });

                }
            })


        }
    },

}
</script>

<style>
.section_name {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
}

.question {
    text-align: center;
    font-weight: bold;
}
</style>


