<template>
    <v-container class="pa-0" fluid>
        <v-card class="brown lighten-1 white--text pa-0 ma-0 elevation-0" dense style="border-radius: 0px">
            <v-card-title class="pt-1 pb-1 overline" > {{ getKind() }}</v-card-title>
        </v-card>
        <!--<v-card outlined v-if="resources !== null" class="pa-0 mt-0">
            <v-card-text class="mt-0 mb-0">
              <v-alert
                class="mt-0 mb-0"
                border="left"
                colored-border
                color="brown lighten-1"
                elevation="0"
                dense
              >
                <v-card-subtitle>In this section you will find all the <b style="text-transform: capitalize">{{getKind()}}</b> collected</v-card-subtitle>
              </v-alert>
            </v-card-text>
        </v-card> -->

        <v-row v-if="resources !== null" class="row pa-1">
            <v-col v-for="r, index in resources" v-if="kind == 'company'" class="col-lg-3 col-md-3 col-12" @key="r.id">
                <CompanyCard :c="r" :index="index"></CompanyCard>
            </v-col>
            <v-col v-for="r, index in resources" v-if="kind == 'audittemplate'" class="col-lg-4 col-md-4 col-12"
                   @key="r.id">
                <AuditTemplateCard :at="r" :index="index"></AuditTemplateCard>
            </v-col>
            <v-col v-for="r, index in resources" v-if="kind == 'audit'" class="col-lg-4 col-md-4 col-12" @key="r.name">
                <AuditCard :ca="r" :index="index"></AuditCard>
            </v-col>
        </v-row>


        <div v-if="resources == null" class="pt-0">
            <v-alert type="info">
                {{ $t('nessun_record', {kind: kind})}}
            </v-alert>
            <v-btn class="brown--text" text @click="goTo()">{{ $t('crea_nuovo')}}</v-btn>
        </div>
        <v-fab-transition>
            <v-btn
                key="newResource"
                color="primary"
                fab
                small
                style="position: fixed; bottom: 15px; right: 15px; z-index: 10"
                @click="goTo()"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>
    </v-container>
</template>

<script>
import AuditCard from '@/components/AuditCard.vue'
import AuditTemplateCard from '@/components/AuditTemplateCard.vue'
import CompanyCard from '@/components/CompanyCard.vue'

export default {
    name:        'ExplorerCard',
    props:       ['kind'],
    components:  {
        AuditCard, CompanyCard, AuditTemplateCard
    },
    data:        function () {
        return {
            resources:   null,
            searchTable: ''
        }
    },
    watch:       {
        '$store.state.itemDeletedCount'(to, from) {
            this.fetch()
        }
    },
    methods:     {
        getKind() {
            let tr = ''
            switch (this.kind) {
                case 'audittemplate':
                    tr = this.$t('audit_template')
                    break
                case 'audit':
                    tr = this.$t("audit_assessment")
                    break
                case 'company':
                    tr = this.$t("elenco_aziende")
                    break;
                default:
                    tr = this.kind
            }
            return tr
        },
        handleRowClick(row) {
            this.goTo(row)
        },
        goTo(item = '__new__') {
            //delete item.data
            let _item = item //== '__new__' ? item : JSON.stringify(item)
            switch (this.kind) {
                case 'company':
                    this.$router.push({name: 'ManageCompany', params: {company: item}})
                    this.$router.push('/manage/company/' + _item)
                    break;
                case 'audittemplate':
                    this.$router.push('/manage/audittemplate/' + _item)
                    break;
                case 'audit':
                    this.$router.push('/manage/audit/' + _item)
                    break;
            }
        },
        prepareTable() {
            this.headers = Object.keys(this.resources[0]).map((r) => {
                return {
                    text:     r,
                    align:    'start',
                    sortable: true,
                    value:    r
                }
            })
        },
        fetch() {
            this.$store.dispatch('get', {

                kind:      this.kind,
                data:      {
                    workspace: this.$store.state.workspace,
                },

                cb: function (err, data) {
                    if (err == null) {
                        if (data.response.length > 0) {
                            this.resources = data.response
                        }

                    }
                }.bind(this)
            })
        }
    },
    mounted:     function () {

    },
    beforeMount: function () {
        this.fetch()
    }
}
</script>
