import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import cookie from 'vue-cookies';

Vue.prototype.$cookie = cookie;

let DEFAULT_API_VERSION = 'v1';

Vue.use(Vuex);

function apiRequest(args, cb) {
    try {
        let apiVersion = args.body !== undefined ? (args.resource === 'batch' ? DEFAULT_API_VERSION : args.body.apiVersion) : DEFAULT_API_VERSION
        let bodyData = args.body === undefined ? null : {data: args.body}
        if (args.token !== null && args.token !== undefined) {
            axios.defaults.headers.common = {'Authorization': `Bearer ${args.token}`}
        }
        if (apiVersion === undefined) {
            apiVersion = DEFAULT_API_VERSION
        }
        let workspace = '-';
        if (args.body !== undefined && args.body.workspace !== undefined) {
            workspace = args.body.workspace;
        }
        if (args.workspace !== undefined && args.workspace !== null) {
            workspace = args.workspace;
        }
        if (args._name !== undefined && args._name !== null) {
            args.kind += '/' + args._name;
        }

        if(bodyData){
            bodyData.data.workspace = workspace;
        }

        let queryParams = args.query;
        if(queryParams){
            queryParams.workspace = workspace;
        }

        axios[args.type](`${args.server}/${apiVersion}/${workspace}/${args.kind}/${args.verb}`,
            bodyData, args.query, {timeout: 1000}).then((res) => {
            cb(null, res)
        }).catch((err) => {
            if (err.code === 'ECONNREFUSED') {
                cb(true, 'Error connecting to API server ' + args.server)
            } else {
                if (err.response !== undefined && err.response.statusText !== undefined) {
                    cb(true, 'Error in response from API server: ' + err.response.statusText)
                } else {
                    cb(true, 'Error in response from API server: Unknown', err)
                }
            }
        })
    } catch (err) {
        console.log(err)
        cb(true, 'App internal error: ' + err)
    }
}

function apiRequestDownload(args, cb) {
    try {
        let apiVersion = args.body !== undefined ? (args.resource === 'batch' ? DEFAULT_API_VERSION : args.body.apiVersion) : DEFAULT_API_VERSION
        let bodyData = args.body === undefined ? null : {data: args.body}
        if (args.token !== null && args.token !== undefined) {
            axios.defaults.headers.common = {'Authorization': `Bearer ${args.token}`}
        }
        if (apiVersion === undefined) {
            apiVersion = DEFAULT_API_VERSION
        }
        let workspace = '-'
        if (args.body !== undefined && args.body.workspace !== undefined) {
            workspace = args.body.workspace
        }
        if (args.workspace !== undefined && args.workspace !== null) {
            workspace = args.workspace
        }
        if (args._name !== undefined && args._name !== null) {
            args.kind += '/' + args._name
        }

        axios.get(`${args.server}/${apiVersion}/${workspace}/${args.kind}/${args.verb}`,
            {responseType: 'blob'}).then((res) => {
            console.log(res)
            cb(null, res)
        }).catch((err) => {
            console.log(err)
            if (err.code === 'ECONNREFUSED') {
                cb(true, 'Error connecting to API server ' + args.server)
            } else {
                if (err.response !== undefined && err.response.statusText !== undefined) {
                    cb(true, 'Error in response from API server: ' + err.response.statusText)
                } else {
                    cb(true, 'Error in response from API server: Unknown', err)
                }
            }
        })
    } catch (err) {
        cb(true, 'App internal error: ' + err)
    }
}

let apiServer = "";
if (window.location.origin == 'http://localhost:8080') {
    // Per lo sviluppo
    apiServer = "http://theroadmap.test:3000";
}

export default new Vuex.Store({
    state:     {
        /**
         *    User status
         */
        isAuth:   false,
        username: '',

        workspace:       null, // Just Workspace CODE (compatibility)
        workspace_current: null, // Full Workspace object
        workspaces_list: [],

        apiServer: apiServer,
        token:     '',

        tipo_spinoff: [
            "Aziendale",
            "Accademico"
        ],

        // Clones
        clones: {
            audittemplate: null
        },

        // Selections, used for sidebar
        selectedAuditTemplate: null,
        selectedCompany:       null,
        selectedAudit:         null,

        itemDeletedCount: 0,

    },
    mutations: {
        user(state, args) {
            Object.keys(args).forEach((k) => {
                state[k] = args[k]
            })
        },
        itemDeletedCount(state) {
            state.itemDeletedCount += 1
        },
        logout(state, args) {
            Vue.prototype.$cookie.set('theroadmap.userToken', null)
            Vue.prototype.$cookie.set('theroadmap.username', null)
            state.isAuth = false
            state.username = ''
            args.cb()
        },
        clone(state, args) {
            state.clones[args.kind] = args.data
        }
    },
    actions:   {
        login(context, args) {
            axios.post(`${context.state.apiServer}/${DEFAULT_API_VERSION}/login`,
                {name: args.username, password: args.password}, {timeout: 2000}).then((res) => {
                console.log(res.data)
                if (res.data.data.isauth === true && res.data.err == null) {
                    context.state.username = args.username;
                    context.state.isAuth = true;
                    context.state.userToken = res.data.token;
                    context.state.workspaces_list = res.data.data.workspaces_list;
                    if (context.state.workspaces_list && context.state.workspaces_list.length > 0) {
                        context.state.workspace = context.state.workspaces_list[0].code; // Di default prendiamo il primo
                        context.state.workspace_current = context.state.workspaces_list[0];
                    }
                    Vue.prototype.$cookie.set('theroadmap.userToken', res.data.token);
                    Vue.prototype.$cookie.set('theroadmap.username', args.username);
                    args.cb(true);
                } else {
                    args.cb(false);
                }

            }).catch((err) => {
                console.log(err)
                args.cb(false)

            })
        },
        loginToken(context, args) {
            axios.post(`${context.state.apiServer}/${DEFAULT_API_VERSION}/login/token`,
                {token: args.token}, {timeout: 2000}).then((res) => {
                console.log(res.data)
                if (res.data.data.status === 'done' && res.data.err == null) {

                    //context.state.username = args.username
                    context.state.isAuth = true;
                    context.state.userToken = args.token;
                    context.state.workspaces_list = res.data.data.workspaces_list;
                    if (context.state.workspaces_list && context.state.workspaces_list.length > 0) {
                        context.state.workspace = context.state.workspaces_list[0].code; // Di default prendiamo il primo
                        context.state.workspace_current = context.state.workspaces_list[0];
                    }

                    args.cb(true)
                } else {
                    args.cb(false)
                }

            }).catch((err) => {
                console.log(err)
                args.cb(false)

            })
        },
        get(context, args) {
            apiRequest({
                server:    context.state.apiServer,
                token:     context.state.userToken,
                type:      'post',
                workspace: context.state.workspace,
                kind:      args.kind.toLowerCase(),
                verb:      'get',
                body:      args.data
            }, (err, response) => {
                if (err) {
                    console.log(err)
                } else {
                    if (args.cb !== undefined) {
                        args.cb(err, response.data)
                    }
                }
            })
        },
        listFiles(context, args) {
            apiRequest({
                server:    context.state.apiServer,
                token:     context.state.userToken,
                type:      'post',
                workspace: context.state.workspace,
                kind:      args.kind.toLowerCase(),
                _name:     args.name,
                verb:      'listfiles'
            }, (err, response) => {
                if (err) {
                    console.log(err)
                } else {
                    if (args.cb !== undefined) {
                        args.cb(err, response.data)
                    }
                }
            })
        },
        downloadFile(context, args) {

            apiRequestDownload({
                server:       context.state.apiServer,
                token:        context.state.userToken,
                type:         'post',
                workspace: context.state.workspace,
                kind:         args.kind.toLowerCase(),
                _name:        args.name,
                verb:         'download',
                responseType: 'blob'
            }, (err, response) => {
                if (err) {
                    console.log(err)
                } else {
                    var fileDownload = require('js-file-download')
                    fileDownload(response.data, args.name.split('-@-')[1])
                }
            })
        },
        deleteFile(context, args) {
            apiRequest({
                server:    context.state.apiServer,
                token:     context.state.userToken,
                type:      'post',
                workspace: context.state.workspace,
                kind:      args.kind.toLowerCase(),
                _name:     args.name,
                verb:      'delete'
            }, (err, response) => {
                console.log(response)
                if (err) {
                    console.log(err)
                } else {
                    if (args.cb !== undefined) {
                        args.cb(err, response.data)
                    }
                }
            })
        },
        describeAuditTemplate(context, args) {
            apiRequest({
                server:    context.state.apiServer,
                token:     context.state.userToken,
                type:      'post',
                workspace: context.state.workspace,
                kind:      args.kind.toLowerCase(),
                verb:      'describe',
                name:      args.name,
                version:   args.version,
                body:      {
                    workspace: context.state.workspace,
                    name:      args.name,
                    version:   args.version,
                },
            }, (err, response) => {
                if (err) {
                    console.log(err)
                } else {
                    if (args.cb !== undefined) {
                        args.cb(err, response.data)
                    }
                }
            })
        },
        describeAudit(context, args) {
            apiRequest({
                server: context.state.apiServer,
                token:  context.state.userToken,
                workspace: context.state.workspace,
                type:   'post',
                kind:   args.kind.toLowerCase(),
                verb:   'describe',
                body:   {
                    audit_id: args.audit_id
                },
            }, (err, response) => {
                if (err) {
                    console.log(err)
                } else {
                    if (args.cb !== undefined) {
                        args.cb(err, response.data)
                    }
                }
            })
        },
        apply(context, args) {
            apiRequest({
                server:    context.state.apiServer,
                token:     context.state.userToken,
                type:      'post',
                workspace: context.state.workspace,
                kind:      args.kind.toLowerCase(),
                verb:      'apply',
                body:      args.data
            }, (err, response) => {
                console.log('---->', err)
                if (args.cb !== undefined) {
                    args.cb(err, response);
                }
            })
        },
        remove(context, args) {
            //context.state.itemDeletedCount += 1

            apiRequest({
                server:    context.state.apiServer,
                token:     context.state.userToken,
                type:      'post',
                workspace: context.state.workspace,
                kind:      args.kind.toLowerCase(),
                verb:      'delete',
                body:      args.data
            }, (err, response) => {
                if (err) {
                    console.log(err)
                } else {
                    context.commit('itemDeletedCount')
                    if (args.cb !== undefined) {
                        args.cb(response.data)
                    }
                }
            })
        },
        export_doc(context, args) {

            let bodyData = args.body === undefined ? null : {data: args.body}

            axios.defaults.headers.common = {'Authorization': `Bearer ${context.state.userToken}`}

            if(bodyData){
                bodyData.data.workspace = context.state.workspace;
            }

            axios({
                url:          `${context.state.apiServer}/export_doc`,
                method:       "post",
                responseType: 'blob',
                data:         bodyData
            }).then((res) => {
                args.cb(null, res)
            }).catch((err) => {
                if (err.code === 'ECONNREFUSED') {
                    args.cb(true, 'Error connecting to API server ' + args.server)
                } else {
                    if (err.response !== undefined && err.response.statusText !== undefined) {
                        args.cb(true, 'Error in response from API server: ' + err.response.statusText)
                    } else {
                        args.cb(true, 'Error in response from API server: Unknown', err)
                    }
                }
            });


        },

        export_pdf(context, args) {

            let bodyData = args.body === undefined ? null : {data: args.body}

            axios.defaults.headers.common = {'Authorization': `Bearer ${context.state.userToken}`}

            if(bodyData){
                bodyData.data.workspace = context.state.workspace;
            }

            axios({
                url:          `${context.state.apiServer}/export_pdf`,
                method:       "post",
                responseType: 'blob',
                data:         bodyData
            }).then((res) => {
                args.cb(null, res)
            }).catch((err) => {
                if (err.code === 'ECONNREFUSED') {
                    args.cb(true, 'Error connecting to API server ' + args.server)
                } else {
                    if (err.response !== undefined && err.response.statusText !== undefined) {
                        args.cb(true, 'Error in response from API server: ' + err.response.statusText)
                    } else {
                        args.cb(true, 'Error in response from API server: Unknown', err)
                    }
                }
            });


        },

        export_xlsx(context, args) {

            let bodyData = args.body === undefined ? null : {data: args.body}

            axios.defaults.headers.common = {'Authorization': `Bearer ${context.state.userToken}`}

            if(bodyData){
                bodyData.data.workspace = context.state.workspace;
            }

            axios({
                url:          `${context.state.apiServer}/export_xlsx`,
                method:       "post",
                responseType: 'blob',
                data:         bodyData
            }).then((res) => {
                args.cb(null, res)
            }).catch((err) => {
                if (err.code === 'ECONNREFUSED') {
                    args.cb(true, 'Error connecting to API server ' + args.server)
                } else {
                    if (err.response !== undefined && err.response.statusText !== undefined) {
                        args.cb(true, 'Error in response from API server: ' + err.response.statusText)
                    } else {
                        args.cb(true, 'Error in response from API server: Unknown', err)
                    }
                }
            });


        },

        export_report(context, args) {

            let bodyData = args.body === undefined ? null : {data: args.body}

            axios.defaults.headers.common = {'Authorization': `Bearer ${context.state.userToken}`}

            if(bodyData){
                bodyData.data.workspace = context.state.workspace;
            }

            axios({
                url:          `${context.state.apiServer}/export_report`,
                method:       "post",
                responseType: 'blob',
                data:         bodyData
            }).then((res) => {
                args.cb(null, res)
            }).catch((err) => {
                if (err.code === 'ECONNREFUSED') {
                    args.cb(true, 'Error connecting to API server ' + args.server)
                } else {
                    if (err.response !== undefined && err.response.statusText !== undefined) {
                        args.cb(true, 'Error in response from API server: ' + err.response.statusText)
                    } else {
                        args.cb(true, 'Error in response from API server: Unknown', err)
                    }
                }
            });


        },

        benchmark_data(context, args) {

            let bodyData = args.body === undefined ? null : {data: args.body}

            axios.defaults.headers.common = {'Authorization': `Bearer ${context.state.userToken}`}

            if(bodyData){
                bodyData.data.workspace = context.state.workspace;
            }

            axios({
                url:    `${context.state.apiServer}/benchmark_data`,
                method: "post",
                data:   bodyData
            }).then((res) => {
                args.cb(null, res)
            }).catch((err) => {
                if (err.code === 'ECONNREFUSED') {
                    args.cb(true, 'Error connecting to API server ' + args.server)
                } else {
                    if (err.response !== undefined && err.response.statusText !== undefined) {
                        args.cb(true, 'Error in response from API server: ' + err.response.statusText)
                    } else {
                        args.cb(true, 'Error in response from API server: Unknown', err)
                    }
                }
            });


        },

        history(context, args) {
            let bodyData = args.body === undefined ? null : {data: args.body}

            axios.defaults.headers.common = {'Authorization': `Bearer ${context.state.userToken}`}

            if(bodyData){
                bodyData.data.workspace = context.state.workspace;
            }

            axios({
                url:    `${context.state.apiServer}/history`,
                method: "post",
                data:   bodyData
            }).then((res) => {
                args.cb(null, res)
            }).catch((err) => {
                if (err.code === 'ECONNREFUSED') {
                    args.cb(true, 'Error connecting to API server ' + args.server)
                } else {
                    if (err.response !== undefined && err.response.statusText !== undefined) {
                        args.cb(true, 'Error in response from API server: ' + err.response.statusText)
                    } else {
                        args.cb(true, 'Error in response from API server: Unknown', err)
                    }
                }
            });
        },

        get_my_workspaces(context, args) {

            axios.defaults.headers.common = {'Authorization': `Bearer ${context.state.userToken}`}

            axios({
                url:    `${context.state.apiServer}/workspaces`,
                method: "get",
            }).then((res) => {
                args.cb(null, res)
            }).catch((err) => {
                if (err.code === 'ECONNREFUSED') {
                    args.cb(true, 'Error connecting to API server ' + args.server)
                } else {
                    if (err.response !== undefined && err.response.statusText !== undefined) {
                        args.cb(true, 'Error in response from API server: ' + err.response.statusText)
                    } else {
                        args.cb(true, 'Error in response from API server: Unknown', err)
                    }
                }
            });
        },

        change_workspace(context, args) {
            context.state.workspace = args.workspace.code;
            context.state.workspace_current = args.workspace;

            console.log("Current Workspace", context.state.workspace);

        }


    },
    modules:   {}
})
