<template>
    <div>
        <VueApexCharts v-if="ready === true" :options="soptions" :series="series" type="radar"></VueApexCharts>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    name:       'ApexRadarSingle',
    props:      [
        'chartData',
        'options',
        'labelSize',
        'bigLabels'
    ],
    components: {
        VueApexCharts
    },
    data:       function () {
        return {
            ready:    false,
            series:   [
                {
                    name: "",
                    data: []
                }
            ],
            soptions: {
                labels:     ['April', 'May', 'June', 'July', 'August', 'September'],
                chart:      {
                    toolbar: {
                        show: false
                    },
                    zoom:    {
                        autoScaleYaxis: false
                    },
                    type:    'radar'
                },
                yaxis:      {
                    show: false,
                    min:  0.0,
                    max:  10.0
                },
                xaxis:      {
                    labels: {
                        style: {}
                    }
                },
                dataLabels: {
                    enabled:    true,
                    background: {
                        enabled:      true,
                        borderRadius: 2,
                    }
                }
            }
        }
    },
    methods:    {
        compute() {

            let maxLabelLength = 10;
            if (this.labelSize) {
                maxLabelLength = parseInt(this.labelSize, 10);
            }
            this.soptions.labels = this.chartData.labels.map((v) => {
                return v.substring(0, maxLabelLength);
            })

            if (this.bigLabels) {
                this.soptions.xaxis.labels.style.fontSize = '11pt';
            }
            this.chartData.datasets.forEach(function (d, i) {
                if (this.series.length <= i) {
                    this.series.push({name: '', data: []})
                }
                this.series[i].name = this.chartData.datasets[i]['label']
                this.series[i].data = (this.chartData.datasets[i]['data']).map((v) => {
                    return Math.round(parseFloat(v) * 100) / 100;
                })
            }.bind(this))
            this.ready = true
        }
    },
    beforeMount() {
        this.compute()
    }
}

</script>
