<template>
    <v-card>
        <v-card-title> {{ at.name }}
            <v-spacer/>
            <v-chip>{{ at.version }}</v-chip>
        </v-card-title>
        <v-card-subtitle>
            {{ at.created_at.split('T')[0] }}
        </v-card-subtitle>

        <v-divider/>
        <v-card-text>

            <v-chip-group column>
                <v-chip v-for="section, index in at.sectionsNames" :style="'border-radius: 100px; color: white; background: ' + getColor(index, 1)"
                        filter @key="section"> {{ section }}
                </v-chip>
            </v-chip-group>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
            <v-btn icon @click="dialogDelete = true">
                <v-icon>fas fa-trash</v-icon>
            </v-btn>
            <v-spacer/>
            <v-btn icon @click="goTo(at)">
                <v-icon>fas fa-edit</v-icon>
            </v-btn>
        </v-card-actions>
        <v-dialog v-model="dialogDelete" width="500">
            <v-card>
                <v-card-title>
                    {{$t('conferma_eliminazione')}}
                </v-card-title>
                <v-card-subtitle>
                    {{$t('messaggio_conferma_eliminazione')}}
                </v-card-subtitle>
                <v-card-actions>
                    <v-btn class="primary--text" text @click="dialogDelete = false">{{ $t("annulla") }}</v-btn>
                    <v-spacer/>
                    <v-btn class="warning--text" text @click="deleteElement()">{{ $t('procedi_eliminazione')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>


import {getColor} from "@/helpers/get_color";

export default {
    name:       'AuditTemplateCard',
    props:      ['at', 'index'],
    components: {},
    data:       function () {
        return {
            radarChartOptionsSmall: {
                legend: {
                    display: false
                },
            },
            score:                  0,
            maxScore:               400,
            converter:              require('number-to-words'),
            dialogDelete:           false
        }
    },
    methods:    {
        goTo(item = '__new__') {
            //delete item.data
            let _item = item// == '__new__' ? item : JSON.stringify(item)
            this.$router.push({name: 'ManageAuditTemplate', params: {audittemplate: item}})
        },
        deleteElement() {
            this.$store.dispatch('remove', {
                kind:      'audittemplate',
                data:      this.at,
                cb:         () =>{
                    this.dialogDelete = false;
                }
            })
        },
        getColor(index, opacity) {
            let hex = getColor(index);
            const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`
            return setOpacity(hex, 0.5)

        }
    }
}
</script>
