<template>
    <v-container class="pa-0" fluid>

        <!-- NEW COMPANY -->
        <div v-if="isNew == true">
            <v-tabs v-model="tabCrm">
                <v-tab>{{ $t('crea') }}</v-tab>
<!--                <v-tab v-if="is_production()">{{ $t('importa_dati') }}</v-tab>-->
            </v-tabs>
            <div v-if="tabCrm == 0">
                <v-card class="pa-2" outlined>
                    <v-card-title class="overline">{{ $t("inserisci_azienda") }}</v-card-title>
                    <v-card-text>
                        <v-text-field
                            :error="validationErros.name  ? true : false"
                            :error-messages="validationErros.name ? validationErros.name : ''"
                            v-model="company.name"
                            class="mt-2" color="blue"
                            dense
                            :label="$t('denominazione') + ' *'"
                            outlined></v-text-field>

                        <v-text-field
                            v-model="company.image_url"
                            :messages="$t('guida_immagine')"
                            type="text"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="$t('url_immagine')"
                            outlined></v-text-field>

                        <v-select
                            :error="validationErros.country  ? true : false"
                            :error-messages="validationErros.country ? validationErros.country : ''"
                            v-model="company.country"
                            :items="nazioni"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="capitalize($t('nazione').toLowerCase()) + ' *'"
                            outlined></v-select>

                        <v-select
                            :error="validationErros.region  ? true : false"
                            :error-messages="validationErros.region ? validationErros.region : ''"
                            v-model="company.region"
                            :items="regioni"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="capitalize($t('regione').toLowerCase()) + ' *'"
                            outlined></v-select>

                        <v-select
                            v-if="isItaly"
                            :error="validationErros.provincia  ? true : false"
                            :error-messages="validationErros.provincia ? validationErros.provincia : ''"
                            v-model="company.provincia"
                            :items="province"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="capitalize($t('provincia').toLowerCase()) + ' *'" outlined></v-select>

                        <v-text-field
                            v-if="!isItaly"
                            v-model="company.provincia"
                            class="mt-2" color="blue"
                            dense
                            :label="$t('provincia')"
                            outlined></v-text-field>

                        <v-text-field v-model="company.via" class="mt-2" color="blue" dense :label="$t('via')"
                                      outlined></v-text-field>
                        <v-text-field v-model="company.citta" class="mt-2" color="blue" dense :label="$t('citta')"
                                      outlined></v-text-field>
                        <v-text-field v-model="company.cap" class="mt-2" color="blue" dense :label="$t('cap')"
                                      outlined></v-text-field>

                        <v-divider/>

                        <v-text-field
                            :error="validationErros.piva  ? true : false"
                            :error-messages="validationErros.piva ? validationErros.piva : ''"
                            v-model="company.piva"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="$t('piva') + ' *'"
                            outlined></v-text-field>

                        <v-divider/>

                        <v-text-field v-model="company.telefono" type="tel" class="mt-2" color="blue" dense
                                      :label="$t('telefono')" outlined></v-text-field>
                        <v-text-field v-model="company.email" class="mt-2" type="email" color="blue" dense
                                      :label="$t('email')"
                                      outlined></v-text-field>
                        <v-text-field v-model="company.web" class="mt-2" color="blue" dense :label="$t('sito_web')"
                                      outlined></v-text-field>

                        <v-divider/>

                        <v-text-field v-model="company.rappresentate_legale" class="mt-2" color="blue" dense
                                      :label="$t('rap_legale')" outlined></v-text-field>
                        <v-text-field v-model="company.avvio_attivita" type="date" class="mt-2" color="blue" dense
                                      :label="$t('avvio_attivita')" outlined></v-text-field>
                        <v-text-field
                            :error="validationErros.numero_impiegati  ? true : false"
                            :error-messages="validationErros.numero_impiegati ? validationErros.numero_impiegati : ''"
                            v-model="company.numero_impiegati"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="$t('n_dipendenti') + ' *'"
                            outlined></v-text-field>

                        <v-text-field v-model="company.numero_impiegati_anno" type="number" class="mt-2" color="blue"
                                      dense :label="$t('anno_rilevamento_dip')" outlined></v-text-field>
                        <v-text-field
                            :error="validationErros.ultimo_fatturato  ? true : false"
                            :error-messages="validationErros.ultimo_fatturato ? validationErros.ultimo_fatturato : ''"
                            v-model="company.ultimo_fatturato"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="$t('ultimo_fatturato') + ' *'"
                            outlined></v-text-field>

                        <v-text-field v-model="company.ultimo_fatturato_anno" type="number" class="mt-2" color="blue"
                                      dense :label="$t('anno_rilevamento_fatturato')" outlined></v-text-field>

                        <v-autocomplete
                            :error="validationErros.ateco  ? true : false"
                            :error-messages="validationErros.ateco ? validationErros.ateco : ''"
                            :item-text="atecoLabel"
                            v-model="company.ateco"
                            :items="ateco"
                            outlined
                            dense
                            class="mt-2" color="blue"
                            :label="$t('codice_ateco') + ' *'"
                        ></v-autocomplete>

                        <v-autocomplete
                            :error="validationErros.nace  ? true : false"
                            :error-messages="validationErros.nace ? validationErros.nace : ''"
                            :item-text="naceLabel"
                            v-model="company.ateco"
                            :items="ateco"
                            outlined
                            dense
                            class="mt-2" color="blue"
                            :label="$t('codice_nace')"
                        ></v-autocomplete>

                        <v-textarea v-model="company.altre_sedi" class="mt-2" color="blue" dense
                                    :label="$t('altre_sedi')"
                                    outlined></v-textarea>
                        <v-textarea v-model="company.struttura_societaria" class="mt-2" color="blue" dense
                                    :label="$t('struttura_societaria')" outlined></v-textarea>
                        <v-checkbox v-model="company.has_cda" class="mt-2" color="blue" dense :label="$t('ha_il_cda')"
                                    outlined></v-checkbox>
                        <v-textarea v-model="company.cda" class="mt-2" color="blue" dense :label="$t('cda')"
                                    outlined></v-textarea>
                        <v-checkbox v-model="company.has_comitato_scientifico" class="mt-2" color="blue" dense
                                    :label="$t('ha_comitato_scientifico')" outlined></v-checkbox>
                        <v-textarea v-model="company.comitato_scientifico" class="mt-2" color="blue" dense
                                    :label="$t('comitato_scientifico')" outlined></v-textarea>
                        <v-checkbox v-model="company.iscrizione_albo_imprese_artigiane" class="mt-2" color="blue" dense
                                    :label="$t('iscrizione_albo_imp_artigiane')" outlined></v-checkbox>
                        <v-checkbox v-model="company.iscrizione_pmi_innovative" class="mt-2" color="blue" dense
                                    :label="$t('iscrizione_pmi_innovative')" outlined></v-checkbox>
                        <v-text-field v-model="company.data_iscrizione_pmi_innovative" type="date" class="mt-2"
                                      color="blue" dense :label="$t('data_pmi_innovative')" outlined></v-text-field>
                        <v-checkbox v-model="company.iscrizione_startup_innovative" class="mt-2" color="blue" dense
                                    :label="$t('iscrizione_startup_innovative')" outlined></v-checkbox>
                        <v-text-field v-model="company.data_iscrizione_startup_innovative" type="date" class="mt-2"
                                      color="blue" dense :label="$t('data_iscrizione_startup_innovative')"
                                      outlined></v-text-field>
                        <v-checkbox v-model="company.is_pmi" class="mt-2" color="blue" dense :label="$t('is_pmi')"
                                    outlined></v-checkbox>
                        <v-checkbox v-model="company.is_startup_o_spinoff" class="mt-2" color="blue" dense
                                    :label="$t('is_startup')" outlined></v-checkbox>
                        <v-select v-model="company.tipo_spinoff" :items="$store.state.tipo_spinoff" class="mt-2"
                                  color="blue" dense :label="$t('tipo_di_spinoff')" outlined></v-select>
                        <v-textarea v-model="company.spinoff_derivato_da" class="mt-2" color="blue" dense
                                    :label="$t('spinoff_da')" outlined></v-textarea>
                        <v-checkbox v-model="company.partecipazione_altre_societa" class="mt-2" color="blue" dense
                                    :label="$t('partecipazione_altre_scocieta')" outlined></v-checkbox>
                        <v-text-field v-model="company.elenco_societa_partecipate" class="mt-2" color="blue" dense
                                      :label="$t('elenco_altr_partecipate')" outlined></v-text-field>

                        <v-divider/>
                        <!--
                                                <v-text-field v-model="company.link" class="mt-6" color="primary" dense label="Image link"
                                                              outlined></v-text-field>
                                                              -->
                    </v-card-text>
                    <v-card-actions class="fixedActions">
                        <v-btn color="primary" :label="$t('save')" dark @click="save"> {{ $t('save') }}</v-btn>
                    </v-card-actions>
                </v-card>

            </div>
<!--            <div v-if="tabCrm == 1">-->
<!--                <v-card class="pa-2" outlined>-->
<!--                    <v-card-title class="overline"> {{ $t('cerca_azienda_crm') }}</v-card-title>-->
<!--                    <v-card-text>-->
<!--                        <v-text-field v-model="searchQuery" class="mt-2" color="primary" :label="$t('cerca')" outlined-->
<!--                                      style="" v-on:keyup.enter="	fetchCrm"></v-text-field>-->
<!--                        <div v-if="searchResult.length !== 0 && searchQuery !== ''">-->
<!--                            <v-data-table-->
<!--                                :headers="searchHeaders"-->
<!--                                :items="searchResult"-->
<!--                                :items-per-page="15"-->
<!--                                class="elevation-0"-->
<!--                            >-->
<!--                                <template v-slot:item.actions="{ item }">-->
<!--                                    <v-btn label="Import" text @click="importItem(item)">-->
<!--                                        {{ $t('importa') }}-->
<!--                                    </v-btn>-->
<!--                                </template>-->
<!--                            </v-data-table>-->
<!--                        </div>-->

<!--                        <br/>-->

<!--                        <div style="font-size: 10pt">-->
<!--                            * {{ $t('campi_obbligatori') }}-->
<!--                        </div>-->
<!--                    </v-card-text>-->
<!--                </v-card>-->
<!--            </div>-->
        </div>


        <!-- ALREADY PRESENT COMPANY -->
        <div v-if="isNew == false">
            <v-tabs v-model="tabCrm" class="brown">
                <v-tab>{{ $t('info_azienda') }}</v-tab>
                <v-tab>{{ $t('documenti_azienda') }}</v-tab>
                <v-tab>{{ $t('audit_assessment') }}</v-tab>
            </v-tabs>

            <div v-if="tabCrm == 0">
                <v-card class="pa-2" outlined>

                    <v-card-text>

                        <v-text-field
                            :error="validationErros.name  ? true : false"
                            :error-messages="validationErros.name ? validationErros.name : ''"
                            v-model="company.name"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="$t('denominazione') + ' *'"
                            outlined></v-text-field>

                        <v-text-field
                            v-model="company.image_url"
                            :messages="$t('guida_immagine')"
                            type="text"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="$t('url_immagine')"
                            outlined></v-text-field>

                        <v-select
                            :error="validationErros.country  ? true : false"
                            :error-messages="validationErros.country ? validationErros.country : ''"
                            v-model="company.country"
                            :items="nazioni"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="capitalize($t('nazione').toLowerCase()) + ' *'" outlined></v-select>

                        <v-select
                            :error="validationErros.region  ? true : false"
                            :error-messages="validationErros.region ? validationErros.region : ''"
                            v-model="company.region"
                            :items="regioni"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="capitalize($t('regione').toLowerCase()) + ' *'" outlined></v-select>

                        <v-select
                            :error="validationErros.provincia  ? true : false"
                            :error-messages="validationErros.provincia ? validationErros.provincia : ''"
                            v-if="isItaly"
                            v-model="company.provincia"
                            :items="province"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="capitalize($t('provincia').toLowerCase()) + ' *'" outlined></v-select>

                        <v-text-field
                            v-if="!isItaly"
                            v-model="company.provincia"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="$t('provincia')"
                            outlined></v-text-field>


                        <v-text-field v-model="company.via" class="mt-2" color="blue" dense :label="$t('via')"
                                      outlined></v-text-field>
                        <v-text-field v-model="company.citta" class="mt-2" color="blue" dense :label="$t('citta')"
                                      outlined></v-text-field>
                        <v-text-field v-model="company.cap" class="mt-2" color="blue" dense :label="$t('cap')"
                                      outlined></v-text-field>


                        <v-divider/>

                        <v-text-field
                            :error="validationErros.piva  ? true : false"
                            :error-messages="validationErros.piva ? validationErros.piva : ''"
                            v-model="company.piva"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="$t('piva') + ' *'"
                            outlined></v-text-field>

                        <v-divider/>

                        <v-text-field v-model="company.telefono" type="tel" class="mt-2" color="blue" dense
                                      :label="$t('telefono')" outlined></v-text-field>
                        <v-text-field v-model="company.email" class="mt-2" type="email" color="blue" dense
                                      :label="$t('email')"
                                      outlined></v-text-field>
                        <v-text-field v-model="company.web" class="mt-2" color="blue" dense :label="$t('sito_web')"
                                      outlined></v-text-field>

                        <v-divider/>

                        <v-text-field v-model="company.rappresentate_legale" class="mt-2" color="blue" dense
                                      :label="$t('rap_legale')" outlined></v-text-field>
                        <v-text-field v-model="company.avvio_attivita" type="date" class="mt-2" color="blue" dense
                                      :label="$t('avvio_attivita')" outlined></v-text-field>

                        <v-text-field
                            :error="validationErros.numero_impiegati  ? true : false"
                            :error-messages="validationErros.numero_impiegati ? validationErros.numero_impiegati : ''"
                            v-model="company.numero_impiegati"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="$t('n_dipendenti') + ' *'"
                            outlined></v-text-field>

                        <v-text-field v-model="company.numero_impiegati_anno" type="number" class="mt-2" color="blue"
                                      dense :label="$t('anno_rilevamento_dip')" outlined></v-text-field>

                        <v-text-field
                            :error="validationErros.ultimo_fatturato  ? true : false"
                            :error-messages="validationErros.ultimo_fatturato ? validationErros.ultimo_fatturato : ''"
                            v-model="company.ultimo_fatturato"
                            class="mt-2"
                            color="blue"
                            dense
                            :label="$t('ultimo_fatturato') + ' *'"
                            outlined></v-text-field>

                        <v-text-field v-model="company.ultimo_fatturato_anno" type="number" class="mt-2" color="blue"
                                      dense :label="$t('anno_rilevamento_fatturato')" outlined></v-text-field>

                        <v-autocomplete
                            :error="validationErros.ateco  ? true : false"
                            :error-messages="validationErros.ateco ? validationErros.ateco : ''"
                            :item-text="atecoLabel"
                            v-model="company.ateco"
                            :items="ateco"
                            outlined
                            dense
                            class="mt-2" color="blue"
                            :label="$t('codice_ateco') + ' *'"
                        ></v-autocomplete>

                        <v-autocomplete
                            :error="validationErros.nace  ? true : false"
                            :error-messages="validationErros.nace ? validationErros.nace : ''"
                            :item-text="naceLabel"
                            v-model="company.ateco"
                            :items="ateco"
                            outlined
                            dense
                            class="mt-2" color="blue"
                            :label="$t('codice_nace')"
                        ></v-autocomplete>

                        <v-textarea v-model="company.altre_sedi" class="mt-2" color="blue" dense
                                    :label="$t('altre_sedi')"
                                    outlined></v-textarea>
                        <v-textarea v-model="company.struttura_societaria" class="mt-2" color="blue" dense
                                    :label="$t('struttura_societaria')" outlined></v-textarea>
                        <v-checkbox v-model="company.has_cda" class="mt-2" color="blue" dense :label="$t('ha_il_cda')"
                                    outlined></v-checkbox>
                        <v-textarea v-model="company.cda" class="mt-2" color="blue" dense :label="$t('cda')"
                                    outlined></v-textarea>
                        <v-checkbox v-model="company.has_comitato_scientifico" class="mt-2" color="blue" dense
                                    :label="$t('ha_comitato_scientifico')" outlined></v-checkbox>
                        <v-textarea v-model="company.comitato_scientifico" class="mt-2" color="blue" dense
                                    :label="$t('comitato_scientifico')" outlined></v-textarea>
                        <v-checkbox v-model="company.iscrizione_albo_imprese_artigiane" class="mt-2" color="blue" dense
                                    :label="$t('iscrizione_albo_imp_artigiane')" outlined></v-checkbox>
                        <v-checkbox v-model="company.iscrizione_pmi_innovative" class="mt-2" color="blue" dense
                                    :label="$t('iscrizione_pmi_innovative')" outlined></v-checkbox>
                        <v-text-field v-model="company.data_iscrizione_pmi_innovative" type="date" class="mt-2"
                                      color="blue" dense :label="$t('data_pmi_innovative')" outlined></v-text-field>
                        <v-checkbox v-model="company.iscrizione_startup_innovative" class="mt-2" color="blue" dense
                                    :label="$t('iscrizione_startup_innovative')" outlined></v-checkbox>
                        <v-text-field v-model="company.data_iscrizione_startup_innovative" type="date" class="mt-2"
                                      color="blue" dense :label="$t('data_iscrizione_startup_innovative')"
                                      outlined></v-text-field>
                        <v-checkbox v-model="company.is_pmi" class="mt-2" color="blue" dense :label="$t('is_pmi')"
                                    outlined></v-checkbox>
                        <v-checkbox v-model="company.is_startup_o_spinoff" class="mt-2" color="blue" dense
                                    :label="$t('is_startup')" outlined></v-checkbox>
                        <v-select v-model="company.tipo_spinoff" :items="$store.state.tipo_spinoff" class="mt-2"
                                  color="blue" dense :label="$t('tipo_di_spinoff')" outlined></v-select>
                        <v-textarea v-model="company.spinoff_derivato_da" class="mt-2" color="blue" dense
                                    :label="$t('spinoff_da')" outlined></v-textarea>
                        <v-checkbox v-model="company.partecipazione_altre_societa" class="mt-2" color="blue" dense
                                    :label="$t('partecipazione_altre_scocieta')" outlined></v-checkbox>
                        <v-text-field v-model="company.elenco_societa_partecipate" class="mt-2" color="blue" dense
                                      :label="$t('elenco_altr_partecipate')" outlined></v-text-field>

                        <v-divider/>


                        <!--
                        <v-text-field v-model="company.link" class="mt-6" color="primary" dense label="Image link"
                                      outlined></v-text-field>
                                      -->

                        <br/>

                        <div style="font-size: 10pt">
                            * {{ $t('campi_obbligatori') }}
                        </div>
                    </v-card-text>

                    <v-card-actions class="fixedActions">
                        <v-btn color="primary" :label="$t('save')" dark @click="save"> {{ $t('save') }}</v-btn>
                    </v-card-actions>


                    <v-btn class="mt-2" @click="deleteCompany()" depressed
                           color="error">{{ $t('elimina') }}
                    </v-btn>

                </v-card>
            </div>

            <div v-if="tabCrm == 1 && company !== undefined && company.id !== undefined">
                <ListDownload :kind="'company'" :name="company.id"/>
            </div>

            <div v-if="tabCrm == 2">
                <v-row v-if="companyAudits.length > 0" class="pa-2">
                    <v-col v-for="ca, index in companyAudits" class="col-lg-3 col-md-4 col-12 pt-0 mt-0">
                        <AuditCard :ca="ca" :index="index" class="ma-2" :key="'company_audit_' + ca.id"></AuditCard>
                    </v-col>
                </v-row>
                <v-row v-else class="pa-2">
                    <v-col class="col-lg-4 col-md-4 col-12 pt-0 mt-6">
                        <v-card>
                            <v-img src="@/assets/undraw_design_components_9vy6.svg"></v-img>
                            <v-card-title>{{ $t('nessun_audit') }}</v-card-title>
                            <v-card-text> {{ $t('nessun_audit_desc', {company_name: company.name}) }}
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="primary" text
                                       @click="$router.push({name: 'ManageAudit', params: {audit: '__new__', company: company}})">
                                    {{ $t('crea_audit') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

        </div>
    </v-container>
</template>

<script>
import axios from 'axios';
import {DateTime} from 'luxon';
import ChartRadarSingle from '@/components/ChartRadarSingle.vue';
import AuditCard from '@/components/AuditCard.vue';
import ateco_nace from '../ateco_nace.json';

import ListDownload from '@/components/ListDownload.vue';
import is_production from "@/helpers/is_production";
import {capitalize} from "@/helpers/capitalize";
import {
    getEuropeanCountries,
    getProvincesForCountryAndRegion,
    getRegionsForCountry
} from "@/helpers/country_data_utils";

export default {
    name: 'ManageCompany',
    data: function () {
        return {
            files: [],
            isNew: true,
            tabCrm: 0,
            searchQuery: '',
            searchResult: [],
            searchHeaders: [],
            ateco: ateco_nace,
            countries: getEuropeanCountries(),

            company: {
                name: '',
                country: 'IT',
                region: 'Trentino-Alto Adige',
                data: {vat: '', tax: '', link: ''},
                id: ''
            },

            companyAudits: [],
            radarDataForAudit: null,

            radarChartOptions: {
                responsive: true,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                display: false,
                                min: 0,
                                max: 1,
                            },
                            gridLines: {
                                display: false,
                            },
                            scaleLabel: {
                                display: false,
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                        }
                    ]
                }
            },

            validationErros: {
                name: false,
                piva: false,
                ateco: false,
                nace: false,
                country: false,
                region: false,
                provincia: false,
                numero_impiegati: false,
                ultimo_fatturato: false
            }
        }
    },
    watch: {
        tabCrm(newVal) {
            if ((newVal == 1 || newVal == 0) && this.isNew == false) {
                this.fetchCompanyAudits()
            }
        },
        searchQuery(newVal, oldVal) {
            if (this.searchQuery.length > 3) {
                this.fetchCrm()
            }
        }
    },
    components: {
        ChartRadarSingle, AuditCard, ListDownload
    },
    computed: {

        nazioni: function () {
            return getEuropeanCountries()
        },

        isItaly: function () {
            return this.company.country === "IT"
        },

        regioni: function () {
            return getRegionsForCountry(this.company.country);
        },

        province: function () {
            return getProvincesForCountryAndRegion(this.company.country, this.company.region);
        }
    },
    methods: {
        capitalize,
        is_production,
        loadRadarChart(ca) {
            let radarDataForAudit = {labels: [], datasets: []}
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            let dd = []
            ca.data.data.sections.forEach(function (section) {
                let _data = section.questions.map(function (q) {
                    return parseInt(q.answer)
                })
                if (_data.length > 0) {
                    _data = _data.reduce(reducer)
                    radarDataForAudit.labels.push(section.name)
                    dd.push(_data)
                }
            }.bind(this))
            radarDataForAudit.datasets[0] = {
                label: this.company.name,
                data: dd,
                borderColor: '#EC407A',
                backgroundColor: '#EC407A',
            }
            return radarDataForAudit
        },
        fetchCompanyAudits() {
            this.$store.dispatch('get', {

                kind: 'audit',
                company_id: this.company.id,
                data: {
                    workspace: this.$store.state.workspace,
                    company_id: this.company.id,
                },
                cb: function (err, data) {
                    if (err == null) {
                        this.companyAudits = data.response
                    }
                }.bind(this)
            })
        },
        fetchCrm() {
            let srv =
                axios.get(`${this.$store.state.apiServer}/v1/plugin/tustena/company?search=${this.searchQuery}`, {timeout: 2000}).then(function (res) {
                    if (res.data.data.count > 0) {
                        this.searchHeaders = Object.keys(res.data.data.data[0]).map((r) => {
                            return {
                                text: r,
                                align: 'start',
                                sortable: true,
                                value: r
                            }
                        })
                        this.searchHeaders.push({text: 'Action', value: 'actions'})
                        this.searchResult = res.data.data.data
                    }
                }.bind(this)).catch((err) => {
                    console.log(err)
                })
        },
        importItem(item) {
            this.company = {
                name: item.denominazione,
                country: 'IT',
                region: 'Trentino-Alto Adige',
                data: {
                    tustena_id: item.id,
                    vat: item.vatID,
                    tax: item.taxID
                }
            }
            this.tabCrm = 0
        },
        addCompanyField() {
            this.company.data["F-" + Object.keys(this.company.data).length] = ''
        },
        save() {

            // Validazione campi
            this.validationErros.name = false;
            this.validationErros.piva = false;
            this.validationErros.ateco = false;
            this.validationErros.nace = false;
            this.validationErros.country = false;
            this.validationErros.region = false;
            this.validationErros.provincia = false;
            this.validationErros.numero_impiegati = false;
            this.validationErros.ultimo_fatturato = false;

            let error_found = false;

            if (!this.company.name) {
                this.validationErros.name = this.$t('campo_obbligatorio');
                error_found = true;
            }

            if (!this.company.piva) {
                this.validationErros.piva = this.$t('campo_obbligatorio');
                error_found = true;
            }

            if (!this.company.ateco) {
                this.validationErros.ateco = this.$t('campo_obbligatorio');
                error_found = true;
            }

            if (!this.company.country) {
                this.validationErros.country = this.$t('campo_obbligatorio');
                error_found = true;
            }

            // if(this.company.country){
            //     if(this.company.country !== 'IT'){
            //         if (!this.company.nace) {
            //             this.validationErros.nace = this.$t('campo_obbligatorio');
            //             error_found = true;
            //         }
            //     }
            // }

            if (!this.company.region) {
                this.validationErros.region = this.$t('campo_obbligatorio');
                error_found = true;
            }

            if (!this.company.provincia) {
                if (this.company.country === 'IT') {
                    this.validationErros.provincia = this.$t('campo_obbligatorio');
                    error_found = true;
                }
            }

            if (this.company.numero_impiegati === "" || this.company.numero_impiegati === null || this.company.numero_impiegati === undefined) {
                this.validationErros.numero_impiegati = [this.$t('campo_obbligatorio')];
                error_found = true;
            }

            if (this.company.ultimo_fatturato === "" || this.company.ultimo_fatturato === null || this.company.ultimo_fatturato === undefined) {
                this.validationErros.ultimo_fatturato = [this.$t('campo_obbligatorio')];
                error_found = true;
            }

            if (error_found) {
                alert(this.$t("errore_campi_obbligatori"));
                return;
            }

            // Verifichiaom se l'azienda c'e' già
            this.$store.dispatch('get', {

                kind: "company",
                data: {
                    workspace: this.$store.state.workspace,
                },

                cb:  (err, data) => {
                    if (err == null) {
                        if (data.response.length > 0) {

                            let piva = this.company.piva;
                            let founded_company = data.response.find(function (a) {
                                return a.piva === piva;
                            });

                            if (founded_company && (parseInt(founded_company.id, 10) !== parseInt(this.company.id, 10))) {
                                alert(this.$t('azienda_gia_presente'));
                                return;
                            }

                            this.$store.dispatch('apply', {
                                kind: 'company',
                                data: this.company,
                                cb: (err) => {
                                    if (err) {
                                        alert(this.$t('errore_salvataggio'));
                                    } else {
                                        this.$toast(this.$t('dati_salvati'));
                                    }
                                }
                            })

                        }

                    }
                }
            })


            //this.$router.push('/manage/company/' + JSON.stringify(this.company))
        },
        deleteCompany() {
            this.$store.dispatch('remove', {
                kind: 'company',
                data: this.company,
                cb: () => {
                    this.$router.push('/explorer/company');
                }
            })
        },
        atecoLabel: function (item) {
            return item.value + " - " + item.ateco_text;
        },
        naceLabel: function (item) {
            return item.nace_value + " - " + item.nace_text;
        },
        countryLabel: function (c) {
            return c.countryName;
        }
    },
    mounted() {

        if (this.$route.params.company == '__new__') {
            this.isNew = true
            this.$store.state.selectedCompany = 'New'
        } else {

            const company_id = this.$route.params.company_id;

            this.$store.dispatch('get', {
                kind: 'company',
                data: {
                    workspace: this.$store.state.workspace,
                },
                cb: (err, data) => {

                    if (err == null) {

                        const company = data.response.find(function (a, index) {
                            return a.id === company_id;
                        });

                        this.company = company;
                        this.isNew = false;

                        if (this.company.avvio_attivita) {
                            let d = DateTime.fromISO(this.company.avvio_attivita);
                            this.company.avvio_attivita = d.toFormat('yyyy-LL-dd');
                        }

                        if (this.company.data_iscrizione_pmi_innovative) {
                            let d = DateTime.fromISO(this.company.data_iscrizione_pmi_innovative);
                            this.company.data_iscrizione_pmi_innovative = d.toFormat('yyyy-LL-dd');
                        }

                        if (this.company.data_iscrizione_startup_innovative) {
                            let d = DateTime.fromISO(this.company.data_iscrizione_startup_innovative);
                            this.company.data_iscrizione_startup_innovative = d.toFormat('yyyy-LL-dd');
                        }

                        this.$store.state.selectedCompany = this.company.name
                        if (this.tabCrm == 0) {
                            this.fetchCompanyAudits()
                        }

                    }

                }
            })

        }

    }
}
</script>

<style>
.v-label {
    text-transform: capitalize;
}

.fixedActions {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px;
    background-color: white;
}

</style>


