<template>
    <v-container class="pa-0" fluid>
        <!-- ALREADY PRESENT -->

        <v-tabs v-model="tabAT">
            <v-tab>{{ $t('modifica_modello_del')}} {{ at.name }}/{{ at.version }}</v-tab>
        </v-tabs>

        <div v-if="tabAT == 0">
            <v-card class="pa-2" outlined>

                <v-card-text class="pb-0 mb-0">
                    <v-row>
                        <v-col class="col-4">
                            <v-text-field v-model="at.name" :disabled="isNew == false" class="mt-2" color="primary" dense
                                          :label="$t('nome')" outlined></v-text-field>
                        </v-col>
                        <v-col class="col-2">
                            <v-text-field v-model="at.version" :disabled="isNew == false" class="mt-2" color="primary" dense
                                          :label="$t('versione')" outlined></v-text-field>
                        </v-col>
                        <v-col class="col-6" style="text-align: right;">
                            <v-btn-toggle dense>
                                <v-btn class="mt-2" @click="clone">
                                    <v-icon>far fa-copy</v-icon>
                                </v-btn>
                                <v-btn :color="showSaveButton == true ? 'primary' : ''" class="mt-2"
                                       @click="saveAuditTemplate">
                                    <v-icon>fas fa-save</v-icon>
                                </v-btn>
                            </v-btn-toggle>

                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-if="at.data !== undefined" class="pt-0 mt-0">

                    <v-row class="pa-0">
                        <v-col class="col-12 pa-3 pt-0">
                            <v-chip-group v-model="tabATSection" column>
                                <!--<p class="pt-2 pr-4"> Sections </p>-->
                                <v-chip v-for="section, index in at.data.sections"
                                        :style="'border-radius: 100px; color: white; background: ' + section.color"
                                        filter @key="section.name">
                                    {{ section.name }}
                                </v-chip>
                                <v-btn v-if="at.data.sections.length > 0" color="primary white--text" icon large
                                       @click="addSectionModal = true">
                                    <v-icon>mdi-plus-circle</v-icon>
                                </v-btn>
                                <v-btn v-else color="primary white--text" large text @click="addSectionModal = true">
                                    <v-icon>mdi-plus-circle</v-icon>
                                    {{ $t('aggiungi_prima_sezione') }}
                                </v-btn>
                            </v-chip-group>
                        </v-col>
                    </v-row>
                    <v-card v-for="section, index in at.data.sections" v-if="index == tabATSection"
                            @key="section.name + '.questions'">
                        <v-toolbar :style="'color: white; background: ' + section.color" class="elevation-0">
                            <v-toolbar-title class="pt-4">
                                <v-text-field v-model="section.name" dark style="width: 300px"></v-text-field>
                            </v-toolbar-title>
                            <v-spacer/>
                            <v-btn dark icon @click="deleteSection(index)">
                                <v-icon small> fas fa-trash</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-row v-for="q, questionIndex in section.questions" class="pa-2"
                               @key="section.section + question.sectionSub">
                            <v-col class="col-1 pt-7">
                                <v-btn :color="section.color" icon @click="deleteQuestion(index, questionIndex)">
                                    <v-icon small> fas fa-minus-circle</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col class="col-1">
                                <v-text-field v-model="q.sectionSub"></v-text-field>
                            </v-col>
                            <v-col class="col-10">
                                <v-text-field v-model="q.question"></v-text-field>
                            </v-col>
                            <v-col class="col-1" style="min-height: 2px">

                            </v-col>
                            <v-col class="col-6">
                                <v-select v-model="q.answerKind" :items="['radio', 'radio-specify-score', 'text', 'multiple']" dense outlined
                                          style="max-width: 300px"></v-select>
                            </v-col>
                            <v-col class="col-5">
                                <v-text-field v-model="q.answerScoreWeight" dense label="Peso"
                                              outlined></v-text-field>
                            </v-col>
                            <v-col class="col-1" style="min-height: 2px">

                            </v-col>
                            <v-col v-if="q.answerKind == 'radio' || q.answerKind == 'radio-specify-score'" class="col-11">

                                <div v-for="n, idx in q.answerOptions">
                                    <v-row style="max-width: 600px">
                                        <v-col class="col-1 pa-0 pt-2">
                                            <v-btn color="secondary" icon small
                                                   @click="deleteAnswerFromQuestion(index, questionIndex, idx)">
                                                <v-icon small> fas fa-minus-circle</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col class="col-4 pa-0">
                                            <v-text-field v-model="q.answerOptions[idx]" dense/>
                                        </v-col>
                                        <v-col class="col-1 pa-0 ml-2">
                                            <v-text-field v-model="q.answerScore[idx]" dense/>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-row style="max-width: 600px">
                                    <v-col class="col-1 pa-0 pt-2">
                                        <v-btn color="primary" icon small
                                               @click="addAnswerToQuestion(index, questionIndex)">
                                            <v-icon>fas fa-plus-circle</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn color="primary" text @click="addQuestion(index)">{{ $t('aggiungi_domanda')}}</v-btn>
                        </v-card-actions>

                    </v-card>
                </v-card-text>
            </v-card>
        </div>

        <!-- Modals -->
        <v-dialog v-if="addSectionModal == true" v-model="addSectionModal" width="500">
            <v-card class="elevation-12">
                <v-toolbar dark dense flat>
                    <v-toolbar-title>{{ $t('aggiungi_sezione')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="pt-6">
                    <v-text-field v-model="section.section" dense outlined :placeholder="$t('id_sezione')"></v-text-field>
                    <v-text-field v-model="section.name" dense outlined :placeholder="$t('nome_sezione')"></v-text-field>
                    <p> {{$t('inserisci_a')}} </p>
                    <v-select v-model="section.indexPosition" :items="['At the end', 'First', 'After the selected section', 'Before the selected section']" dense
                              outlined
                              :placeholder="$t('inserisci_alla_posizione')"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="addSection">{{ $t('aggiungi')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </v-container>
</template>

<script>
import {getColor} from "@/helpers/get_color";

export default {
    name:       'ManageAuditTemplate',
    data:       function () {
        return {
            e6:            1,
            isNew:         true,
            tabAT:         0,
            tabATSection:  0,
            searchQuery:   '',
            searchResult:  [],
            searchHeaders: [],
            at:            {},

            showSaveButton:       false,
            enableShowSaveButton: false,

            // Modals
            addSectionModal: false,

            // Modals temp structure,
            section: {
                name:          '',
                section:       '',
                indexPosition: 'At the end'
            },

            question: {
                sectionIndex:  0,
                questionIndex: 0,
            }

        }
    },
    watch:      {
        at: {
            handler(newVal, oldVal) {
                if (Object.keys(oldVal).length == 0) {
                    // Do nothing at loading
                } else {
                    if (this.enableShowSaveButton == true) {
                        this.showSaveButton = true
                    }
                }
            },
            deep: true
        }
    },
    components: {},
    methods:    {
        saveAuditTemplate() {
            this.$store.dispatch('apply', {
                kind:      'audittemplate',
                data:      this.at,
                cb:        function () {
                    this.showSaveButton = false
                }.bind(this)
            })
        },
        addAnswerToQuestion(sectionIndex, questionIndex) {
            this.at.data.sections[sectionIndex].questions[questionIndex].answerScore.push('')
            this.at.data.sections[sectionIndex].questions[questionIndex].answerOptions.push('')
        },
        deleteAnswerFromQuestion(sectionIndex, questionIndex, idx) {
            this.at.data.sections[sectionIndex].questions[questionIndex].answerScore.splice(idx, 1)
            this.at.data.sections[sectionIndex].questions[questionIndex].answerOptions.splice(idx, 1)
        },
        editQuestion(sectionIndex, questionIndex) {
            this.question.sectionIndex  = sectionIndex
            this.question.questionIndex = questionIndex
            this.editQuestionModal      = true
        },
        deleteQuestion(sectionIndex, questionIndex) {
            this.at.data.sections[sectionIndex].questions.splice(questionIndex, 1)
        },
        addQuestion(sectionIndex) {
            this.at.data.sections[sectionIndex].questions.push({
                sectionSub:    '',
                question:      '',
                answerKind:    'radio',
                answerOptions: ['yes', 'no'],
                answerScore:   [10, 0],
                defaultOption: 1,
            })
        },
        addSection() {
            if (this.section.indexPosition == 'First') {
                this.at.data.sections.unshift({
                    section:   this.section.section,
                    name:      this.section.name,
                    questions: [],
                    color:     this.getColor(this.at.data.sections.length)
                })
            } else if (this.section.indexPosition == 'At the end') {
                this.at.data.sections.push({
                    section:   this.section.section,
                    name:      this.section.name,
                    questions: [],
                    color:     this.getColor(this.at.data.sections.length)
                })
            } else {
                let indexToInsert = 0
                for (var i = 0; i < this.at.data.sections.length; i += 1) {
                    if (this.section.indexPosition == 'After the selected section' && i == this.tabATSection) {
                        indexToInsert = i + 1
                        break
                    }
                    if (this.section.indexPosition == 'Before the selected section' && (i - 1) == this.tabATSection) {
                        indexToInsert = (i - 1)
                        break
                    }
                }
                this.at.data.sections.splice(indexToInsert, 0, {
                    section:   this.section.section,
                    name:      this.section.name,
                    questions: [],
                    color:     this.getColor(this.at.data.sections.length)
                })

            }
        },
        deleteSection(sectionIndex) {
            this.at.data.sections.splice(sectionIndex, 1)
        },
        clone() {
            this.$store.commit('clone', {kind: 'audittemplate', data: this.at})
            this.$router.push('/manage/audittemplate/__clone__')
        },
        getColor(index) {
            return getColor(index);
        },
        fetch() {
            this.$store.dispatch('describeAuditTemplate', {
                kind:      'audittemplate',
                name:      this.at.name,
                version:   this.at.version,

                cb: function (err, data) {
                    if (err == null && data.response.length == 1) {
                        this.at = data.response[0]
                        this.at.data.sections.forEach(function (s, index) {
                            if (s.color == undefined) {
                                s.color = this.getColor(index)
                            }
                        }.bind(this))
                        setTimeout(function () {
                            this.enableShowSaveButton = true
                        }.bind(this), 500)

                    }
                }.bind(this)
            })
        }

    },
    mounted() {
        if (this.$route.params.audittemplate == '__new__') {
            this.$store.state.selectedAuditTemplate = 'New'
            this.at                                 = {
                name:      'A new audit template',
                version:   'v1',
                workspace: this.$store.state.workspace,
                data:      {
                    sections: []
                }
            }
        } else if (this.$route.params.audittemplate == '__clone__') {
            this.at                                 = this.$store.state.clones.audittemplate
            this.$store.state.selectedAuditTemplate = this.at.name
            delete this.at.id
            this.at.name    = null
            this.at.version = null
            this.at.created_at = null
            setTimeout(function () {
                this.enableShowSaveButton = true
            }.bind(this), 500)
        } else {
            this.isNew                              = false
            this.at                                 = this.$route.params.audittemplate
            this.$store.state.selectedAuditTemplate = this.at.name
            this.fetch()
        }
    }

}
</script>
