<template>
    <v-container id="LoginPageContainer" class="pa-0 ma-0 white" fluid>
        <v-row class="pa-0 ma-0">
            <v-col id="LoginPage" class="col-1 col-md-8 col-lg-8 mainbackground darken-4 elevation-0 pa-2">

                <v-footer v-if="$store.state.isAuth === false"
                          style="background: rgba(0,0,0,0); position: absolute; bottom: 0; right: 0; flex-direction: column">
                    <img src="../assets/sponsor_2.jpg" style="width: 250px;display: block;margin-bottom: 20px" />
                    <v-flex class='text-right text-xs-center overline'> © 2021 Trentino sviluppo</v-flex>
                </v-footer>

            </v-col>
            <v-col class="col-11 col-md-4 col-lg-4 mainbackground darken-1 elevation-12">
                <v-card class="elevation-0 pa-12">
                    <v-img class="ml-3" src="@/assets/logots.png" style="margin-top: 15vh;width: 290px"></v-img>
                    <v-card-title class="mt-6" style="font-weight: 300;">
                        <b class="">INTERNATIONAL ASSESSMENT</b>
                    </v-card-title>

                    <v-card-text class="mt-2 pa-0">
                        <v-card-subtitle class="overline" style="font-weight: 300">
                            {{ $t('login')}}
                        </v-card-subtitle>
                        <v-text-field
                            id="username"
                            v-model="username"

                            class="brown--text ml-4 pr-4"
                            dense

                            outlined
                            :placeholder="$t('username')"
                            prepend-icon="fas fa-user-circle brown--text"

                            type="text"
                        >
                        </v-text-field>
                        <v-text-field
                            id="password"
                            v-model="password"

                            class="brown--text ml-4 pr-4"
                            dense

                            outlined
                            :placeholder="$t('password')"
                            prepend-icon="fa-lock  brown--text"

                            type="password"
                            @keyup.enter="login()"
                        >
                        </v-text-field>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="ma-0 mr-2" color="primary" icon text v-on:click="login">
                            <v-icon>fas fa-sign-in-alt</v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-card-text class="mt-2 pa-0 ml-4 pr-4">
                        <v-alert
                            v-if="wrongAuth === true"
                            dense

                            type="error"
                        >
                            {{ $t('auth_error')}}
                        </v-alert>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
export default {
    name:       'Dashboard',
    data:       function () {
        return {
            username:  '',
            password:  '',
            token:     '',
            wrongAuth: false
        }
    },
    mounted() {
        // Verifichiamo se è presente un token nell'url. Nel caso procediamo
        const urlParams = new URLSearchParams(location.search);
        for (const [key, value] of urlParams) {
            if(key === "token"){
                this.token =value;
                this.loginToken();
            }
        }
    },
    methods:    {
        login() {
            this.wrongAuth = false
            this.$store.dispatch('login', {
                username: this.username,
                password: this.password,
                cb:       function (isAuth) {
                    console.log('IS AUTH', isAuth)
                    if (isAuth === true) {
                        this.$router.push('/match')
                    } else {
                        this.wrongAuth = true
                    }
                }.bind(this)
            })
        },
        loginToken() {
            this.wrongAuth = false
            this.$store.dispatch('loginToken', {
                token: this.token,
                cb:    function (isAuth) {
                    console.log('IS AUTH', isAuth)
                    if (isAuth === true) {
                        this.$router.push('/match')
                    } else {
                        this.wrongAuth = true
                    }
                }.bind(this)
            })
        }
    },
    components: {},
}
</script>
<style>
#LoginPageContainer {
    overflow-y: hidden;
}

#LoginPage {
    background: url(../assets/login.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    background-position: center;
}

#LoginPage2 {
    background: url(../assets/john-lockwood-FcLq69V7Rsc-unsplash.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    background-position: center;
}
</style>
