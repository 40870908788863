import countries from '../countries.json';
import european_countries from '../european_countries.json';

export function getEuropeanCountries() {
    return countries
        .filter((c) => {
            return european_countries.indexOf(c.countryShortCode) >= 0;
        }).map((c) => {
            return {
                value: c.countryShortCode,
                text:  c.countryName
            }
        });
}

export function getCountryFromCode(code) {
    return countries.find((c) => {
        return c.countryShortCode === code;
    });
}

export function getCountryFromRegion(region){
    for(let i = 0; i < countries.length; i++){
        let regions = getRegionsForCountry(countries[i].countryShortCode);
        for(let j = 0; j < regions.length; j++){
            if(regions[j].value === region){
                return countries[i];
            }
        }
    }
}

export function getRegionsForCountry(countryShortCode) {

    const country = getCountryFromCode(countryShortCode);
    if (!country) {
        console.log("Unable to find country: " + european_countries)
        return null;
    }

    if (country.countryShortCode === "IT") {

        // Dobbiamo estrarre le regioni
        let regioni = [];
        let founded_regions = {};

        country.regions.forEach((provincia) => {
            let regione = provincia.regione;

            if (!founded_regions.hasOwnProperty(regione)) {
                regioni.push({
                    value: regione,
                    text:  regione,
                });
                founded_regions[regione] = true;
            }

        });

        regioni = regioni.sort((a, b) => {
            return a.text.localeCompare(b.text);
        });

        return regioni;

    } else {

        // Le regions sono già le regioni
        return country.regions.map((region) => {
            return {
                value: region.shortCode,
                text:  region.name
            }
        })

    }


}

export function getProvincesForCountryAndRegion(countryShortCode, region) {
    const country = getCountryFromCode(countryShortCode);
    if (!country) {
        console.log("Unable to find country: " + european_countries)
        return null;
    }

    if (country.countryShortCode === "IT") {

        // Dobbiamo estrarre le province
        let province = [];

        country.regions.forEach((provincia) => {
            if (provincia.regione === region) {
                province.push({
                    value: provincia.shortCode,
                    text:  provincia.name,
                });
            }
        });

        province = province.sort((a, b) => {
            return a.text.localeCompare(b.text);
        });

        return province;

    } else {
        // Per ora non abbiamo province per altri paesi
        return [];
    }

}

export function getCountryNameFromCode(countryCode){
    if(!countryCode  || countryCode === "") return "";
    const country = getCountryFromCode(countryCode);
    if(!country) return countryCode;
    return country.countryName;
}

export function getRegionNameFromCode(countryCode, regionCode){
    if(!countryCode  || countryCode === "") return "";
    if(!regionCode  || regionCode === "") return "";

    const regions = getRegionsForCountry(countryCode);

    const region = regions.find((region) => {
        return region.value === regionCode;
    })

    if(!region) return regionCode;
    return region.text;
}

export function getProvinceNameFromCode(countryCode, regionCode, provinceCode){
    if(!countryCode  || countryCode === "") return "";
    if(!regionCode  || regionCode === "") return "";
    if(!provinceCode  || provinceCode === "") return "";

    const provinces = getProvincesForCountryAndRegion(countryCode, regionCode);

    const province = provinces.find((province) => {
        return province.value === provinceCode;
    })

    if(!province) return provinceCode;
    return province.text;
}
