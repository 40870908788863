//****************************************************************************************************************************
// ATTENZIONE: questo file è in server/src/langs il file in webapp è un link
//****************************************************************************************************************************

export default {
    analisi:                            "Analisi",
    confronto:                          "Confronto",
    confronta_audit:                    "Confronta audit/assessment",
    benchmark:                          "Benchmark",
    compara_azienda:                    "Compara azienda con <br/>gruppo target",
    audit_assessment:                   "AUDIT/ASSESSMENT",
    audits:                             "Audits",
    elenco_audit:                       "Elenco audit/assessment",
    crea_modifica:                      "Crea e modifica",
    modelli:                            "Modelli",
    audit_template:                     "Audit/Assessmet template",
    aziende:                            "Aziende",
    azienda:                            "Azienda",
    elenco_aziende:                     "Elenco aziende",
    apri:                               "Apri",
    conferma_eliminazione:              "Conferma l'eliminazione",
    messaggio_conferma_eliminazione:    "Vuoi davvero cancellare questo elemento? l'operazione non potrà essere annullata",
    annulla:                            "Annulla",
    procedi_eliminazione:               "Procedi con l'eliminazione",
    sections:                           "Sezioni",
    confronta_title:                    "CONFRONTA PIU’ VERSIONI DELL’AUDIT/ASSESSMENT",
    inizia_confronto:                   "Inizia il confronto",
    nuovo:                              "Nuovo",
    seleziona_versione_confronto:       "Seleziona le versioni da confrontare",
    seleziona:                          "Seleziona",
    drop_to_upload:                     "Trascina un file per caricarlo",
    clicca_per_selez_file:              "Clicca per selezionare i file",
    start_upload:                       "Inizia il caricamento",
    stop_upload:                        "Interrompi il caricamento",
    audit_from_date:                    "Audit/assessment del ",
    export:                             "Esporta",
    esporta_formato:                    "Esporta formato",
    save:                               "Salva",
    seleziona_paesi:                    "Seleziona i paesi",
    resport_saved:                      "Report salvato",
    selezionare_azienda:                "Selezionare un'azienda:",
    filtro_settore:                     "SETTORE",
    filtro_dipendenti:                  "DIPENDENTI",
    filtro_fatturato:                   "FATTURATO",
    filtro_anno_assessment:             "ANNO ASSESSMENT",
    regioni:                            "REGIONI",
    province:                           "PROVINCE",
    aggiorna:                           "Aggiorna",
    nome:                               "Nome",
    regione:                            "REGIONE",
    provincia:                          "PROVINCIA",
    aziende_trovate:                    "Aziende trovate",
    tutti:                              "Tutti",
    anno_in_corso:                      "Anno in corso",
    anno_precedente:                    "Anno precedente",
    due_anni_precedenti:                "Due anni precedenti",
    media_benchmark:                    "MEDIA BENCHMARK",
    cerca:                              "Cerca",
    crea_nuovo:                         "Crea nuovo",
    nessun_elemento:                    "Non ci sono { kind } inizia creandone uno",
    nessun_record:                      "Non ci sono record { kind } inizia creandone uno",
    login:                              "Login",
    username:                           "Username",
    password:                           "Password",
    auth_error:                         "Errore di autenticazione",
    versioni:                           "Versioni",
    grafico:                            "Grafico",
    documenti:                          "Documenti",
    report:                             "Report",
    modello_audit:                      "Modello audit/assessment",
    date_format:                        "Data YY-MM-DD",
    risposta:                           "Risposta",
    risposte:                           "Risposte",
    note:                               "Note",
    nota:                               "Nota",
    adeguamento_punteggio:              "Adeguamento punteggio",
    precedente:                         "Precedente",
    prossimo:                           "Prossimo",
    save_chart:                         "Salva grafico",
    audit_saved:                        "Audit salvato",
    modifica_modello_del:               "Modifica il modello di audit",
    versione:                           "Versione",
    aggiungi_prima_sezione:             "Aggiungi la prima sezione",
    aggiungi_domanda:                   "Aggiungi domanda",
    aggiungi_sezione:                   "Aggiungi sezione",
    inserisci_a:                        "Inserisci a",
    id_sezione:                         "ID sezione",
    nome_sezione:                       "Nome sezione",
    inserisci_alla_posizione:           "Inserisci alla posizione",
    aggiungi:                           "Aggiungi",
    crea:                               "CREA",
    importa_dati:                       "Importa dati",
    inserisci_azienda:                  "Inserisci nuova azienda",
    denominazione:                      "Denominazione",
    url_immagine:                       "URL immagine",
    via:                                "Via",
    citta:                              "Città",
    cap:                                "CAP",
    nazione:                            "Nazione",
    nazioni:                            "Nazioni",
    piva:                               "Partita IVA",
    telefono:                           "Telefono",
    email:                              "Email",
    sito_web:                           "Sito web",
    rap_legale:                         "Rappresentate legale",
    avvio_attivita:                     "Avvio attività",
    n_dipendenti:                       "Numero dipendenti",
    anno_rilevamento_dip:               "Anno del rilevamento numero dipendenti",
    ultimo_fatturato:                   "Ultimo fatturato",
    anno_rilevamento_fatturato:         "Anno del rilevamento ultimo fatturato",
    codice_ateco:                       "Codice ATECO",
    codice_nace:                        "Codice NACE",
    altre_sedi:                         "Altre sedi",
    struttura_societaria:               "Struttura societaria",
    ha_il_cda:                          "Ha il CDA?",
    cda:                                "CDA",
    ha_comitato_scientifico:            "Ha un comitato scientifico?",
    comitato_scientifico:               "Comitato scientifico",
    iscrizione_albo_imp_artigiane:      "Iscrizione albo delle imprese artigiane",
    iscrizione_pmi_innovative:          "Iscrizione PMI innovative",
    data_pmi_innovative:                "Data iscrizione PMI innovative",
    iscrizione_startup_innovative:      "Iscrizione startup innovative",
    data_iscrizione_startup_innovative: "Data iscrizione startup innovative",
    is_pmi:                             "E' una PMI?",
    is_startup:                         "E' uno startup o uno spin-off?",
    tipo_di_spinoff:                    "Tipo di spin-off",
    spinoff_da:                         "Spin-off derivato da",
    partecipazione_altre_scocieta:      "Partecipazione ad altre società",
    elenco_altr_partecipate:            "Elenco delle altre società partecipate",
    cerca_azienda_crm:                  "Cerca l'azienda nel CRM",
    importa:                            "Importa",
    info_azienda:                       "Informazioni azienda",
    documenti_azienda:                  "Documenti azienda",
    elimina:                            "Elimina",
    nessun_audit:                       "Nessun audit",
    nessun_audit_desc:                  "Non ci sono audit per l'azienda { company_name }, inizia creandone uno.",
    crea_audit:                         "Crea audit",
    errore_salvataggio:                 "Errore durante il salvataggio",
    confronto_intro:                    "Confronta il posizionamento delle aziende nei 7 ambiti di indagine selezionando due o più audit/assessment dalla lista",
    seleziona_hint:                     "Seleziona gli audit/assessment da confrontare",
    ultimo_aggiornamento:               "Ultimo aggiornamento",
    autore:                             "Autore",
    dati_generali:                      "Dati generali",
    contatti:                           "Contatti",
    altre_info:                         "Altre informazioni",
    yes:                                "Sì",
    no:                                 "No",
    data:                               "Data",
    referente:                          "Referente",
    sezione:                            "Sezione",
    domanda:                            "Domanda",
    punti:                              "Punti",
    risposta_testuale:                  "Risposta Testuale",
    premessa:                           "Premessa",
    descrizione_azienda:                "Descrizione azienda",
    paesi_dove_esporta:                 "Paesi dove l’azienda vende i suoi prodotti / servizi",
    situazione_economica:               "Situazione economico finanziaria",
    risultati_audit:                    "Risultati audit",
    prodotto:                           "Prodotto / servizio",
    mercati:                            "Mercati",
    strategie_export:                   "Strategie",
    risorse_umane:                      "Risorse umane",
    processi:                           "Processi",
    export_digitale:                    "Export digitale",
    comunicazione_promozione:           "Comunicazione / promozione",
    sintesi_assessment:                 "Sintesi assessment",
    punti_forza:                        "Punti di forza",
    ambiti_miglioramento:               "Ambiti di miglioramento",
    proposta_attivita:                  "Proposta attività",
    indicatori:                         "Indicatori",
    indice_redditivita:                 "Redditività (MOL/RICAVI)",
    indice_solidita:                    "Solidità patrimoniale (PFN/PN)",
    indice_equilibrio:                  "Equilibrio economico finanziario (PFN/MOL)",
    indice_indipendenza:                "Indipendenza finanziaria (PN/TOTALE ATTIVO)",
    utile:                              "UTILE",
    mol:                                "MOL",
    ricavi:                             "RICAVI",
    di:                                 "di",
    anni:                               "Anni",
    spiegazione_mol:                    "MOL (conto economico: differenza valore e costi produzione [A-B] + tot ammortamenti e svalutazioni)",
    spiegazione_ricevi:                 "RICAVI (A.1 conto economico)",
    spiegazione_posizione_finanziaria:  "POSIZIONE FINANZIARIA NETTA (debiti bancari - disponibilità liquide)",
    spiegazione_patrimonio:             "PATRIMONIO NETTO (stato patrimoniale: totale patrimonio netto)",
    spiegazione_totale_attivo:          "TOTALE ATTIVO (stato patrimoniale: totale attivo)",
    spiegazione_utile:                  "UTILE (ultima voce conto economico: utile (perdita) dell'esercizio) ",
    assessor:                           "Assessor",
    audit_salvato:                      "Audit salvato",
    dati_salvati:                       "Dati salvati",

    // 2024-06-01
    punteggio: "Punteggio",
    azienda_gia_presente: "L'azienda è già presente",
    numero_aziende: "Numero aziende",
    codici_ateco: "CODICI",
    numero_impiegati_medio: "N impiegati medio",
    fatturato_medio: "Fatturato medio",
    campo_obbligatorio: "Campo obbligatorio",
    campi_obbligatori: "Campi obbligatori",
    errore_campi_obbligatori: "Compilare tutti i campi obbligatori",
    guida_immagine: "E' necessario inserire l'url completo di un'immagine ad esempio https://placehold.co/600x400",
    precompila_report: "Precompila report",
    confirm_precompila_report: "ATTENZIONE procedendo con la precompilazione del report tutti i testi già inseriti verranno sovrascritti. Vuoi procedere?",

}
