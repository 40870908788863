<template>
    <v-container class="pa-0 white" fluid>
        <!-- ALREADY PRESENT -->

        <v-tabs v-model="tabAT">
            <v-tab>
                <b v-if="companyIndex !== undefined && companies[companyIndex] !== undefined"
                   class="ml-2">{{ companies[companyIndex].name }}</b>
                <b v-else class="ml-2"> {{ $t('audit_assessment') }} </b>
            </v-tab>
            <v-tab v-show="isNew == false">{{ $t('versioni') }}</v-tab>
            <v-tab v-show="isNew == false">{{ $t('grafico') }}</v-tab>
            <v-tab v-show="isNew == false">{{ $t('documenti') }}</v-tab>
            <v-tab v-show="isNew == false">{{ $t('report') }}</v-tab>
        </v-tabs>

        <div v-if="tabAT == 0">
            <v-card class="pa-2" outlined>
                <v-card-text v-if="isNew == false" class="pb-0 mb-0">
                    <v-row>
                        <v-col class="col-4">
                            <h2> {{ at.company_name }}</h2>
                            <h4 class="overline"> {{ $t('audit_from_date') }} {{ dataAggiornamento }}</h4>
                        </v-col>

                        <v-col class="col-md-8 col-8" style="text-align: right">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="primary"
                                        text
                                        style="margin-right: 100px"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        {{ $t('export') }}
                                    </v-btn>
                                </template>
                                <v-list>

                                    <v-list-item @click="exportAudit">
                                        <v-list-item-title>{{ $t('export') }} (docx)</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item @click="exportAuditXLSX">
                                        <v-list-item-title>{{ $t('export') }} (xlsx)</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item @click="exportAuditPDF">
                                        <v-list-item-title>{{ $t('export') }} (pdf)</v-list-item-title>
                                    </v-list-item>

                                </v-list>
                            </v-menu>

                            <v-btn
                                v-if="isNew == false"
                                color="primary"
                                style="position: fixed;right: 38px;z-index: 10"
                                dark
                                @click="resaveAudit"
                            >
                                {{ $t('save') }}
                            </v-btn>

                        </v-col>


                    </v-row>
                </v-card-text>
                <v-card-text v-if="isNew == true" class="pb-0 mb-0">
                    <v-row>
                        <v-col class="col-2">
                            <v-select v-model="audittemplateIndex" :items="audittemplates" class="mt-2" color="primary"
                                      dense
                                      item-text="text" item-value="index" :label="$t('modello_audit')"
                                      outlined></v-select>
                        </v-col>
                        <v-col class="col-3">
                            <v-select v-model="companyIndex" :disabled="isNew == false" :items="companies" class="mt-2"
                                      color="primary"
                                      dense item-text="name" item-value="index" :label="$t('azienda')"
                                      outlined></v-select>
                        </v-col>
                        <v-col class="col-4">
                            <v-text-field v-model="audit.name" :disabled="isNew == false" class="mt-2" color="primary"
                                          dense
                                          :label="$t('nome') + ' ' + $t('assessor')  " outlined></v-text-field>
                        </v-col>


                        <v-col class="col-2">
                            <v-text-field v-model="audit.date" :disabled="isNew == false" class="mt-2" color="primary"
                                          dense
                                          :label="$t('date_format')" outlined></v-text-field>
                        </v-col>
                        <v-col class="col-1">
                            <v-btn class="mt-2" icon @click="startAudit">
                                <v-icon>fas fa-play</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-if="at.data !== undefined" class="pt-0 mt-0">

                    <div v-for="section, index in at.data.sections" @key="section.name">
                        <v-stepper
                            v-model="e6"
                            class="mt-6"
                            vertical
                        >
                            <v-stepper-step
                                :color="section.color"
                                :complete="e6 > index + 1"
                                :step="index + 1"
                                editable
                            >
                                <b class="overline"> {{ section.name }}</b>
                            </v-stepper-step>

                            <v-stepper-content :step="index + 1">
                                <v-card v-if="e6 === (index + 1)" v-for="q, questionIndex in section.questions"
                                        class="mb-0 elevation-0"
                                        @key="section.section + question.sectionSub"
                                >
                                    <v-row
                                        :class="'pa-2 question_item question_type_' + q.answerKind + ' ' + isQuantitative(q)">
                                        <v-col class="col-1 pt-0">
                                            <h3>{{ q.sectionSub }}</h3>
                                        </v-col>

                                        <v-col class="col-10 pt-0">
                                            <h4>{{ q.question }}</h4>
                                        </v-col>

                                        <v-col class="col-1 pt-0">
                                            <v-btn
                                                v-if="q.hasOwnProperty('instructions') && q.instruction !== ''"
                                                @click="() => {
                                                    showHelpDialog = true;;
                                                    helpMessage = q.instructions;
                                                }"
                                                class="mx-0"
                                                fab
                                                dark
                                                small
                                                color="primary"
                                            >
                                                <v-icon dark>
                                                    mdi-help
                                                </v-icon>
                                            </v-btn>
                                        </v-col>

                                        <v-col class="col-1" style="min-height: 2px"></v-col>

                                        <div class="col-11">

                                            <div class="row" v-if="q.answerKind === 'radio'">

                                                <v-col class="col-11 pt-0">
                                                    <v-radio-group v-model="q.answer" class="pt-0" row>
                                                        <v-radio v-for="n, idx in q.answerOptions"
                                                                 :key="n"
                                                                 :color="section.color"
                                                                 :label="`${n} (${q.answerScore[idx]})`"
                                                                 :value="idx"
                                                        ></v-radio>
                                                    </v-radio-group>
                                                </v-col>

                                                <v-col class="col-1" style="min-height: 2px"></v-col>

                                                <v-col class="col-11 pt-0">
                                                    <v-textarea v-model="q.text_answer" auto-grow dense
                                                                :label="$t('risposta')"
                                                                outlined
                                                                rows="2"></v-textarea>
                                                </v-col>

                                                <v-col class="col-1" style="min-height: 2px"></v-col>

                                                <v-col class="col-11 pt-0">
                                                    <v-textarea v-model="q.notes" auto-grow
                                                                background-color="grey lighten-2"
                                                                dense
                                                                :label="$t('note')" outlined rows="2"></v-textarea>
                                                </v-col>

                                            </div>

                                            <div class="row" v-if="q.answerKind === 'radio-specify-score'">

                                                <v-col class="col-6 pt-0">
                                                    <v-radio-group :value="q.answer" class="pt-0" row
                                                                   @change="(value) => {changeQuestionValue(index, questionIndex, value)}">

                                                        <v-radio :color="section.color"
                                                                 :label="q.answerOptions[1] + ' (0)'"
                                                                 :value="1"
                                                        ></v-radio>

                                                        <v-radio :color="section.color"
                                                                 :label="q.answerOptions[0]"
                                                                 :value="0"
                                                        ></v-radio>
                                                    </v-radio-group>
                                                </v-col>

                                                <v-col class="col-5 pt-0">
                                                    {{ $t('punteggio') }}: {{ q.answerScoreOverride }}
                                                    <vue-slider
                                                        @change="(value) => {updateScoreValue(index, questionIndex, value)}"
                                                        :value="q.answerScoreOverride" :min="1" :max="10"/>
                                                </v-col>

                                                <v-col class="col-11 pt-0">
                                                    <v-textarea v-model="q.text_answer" auto-grow dense
                                                                :label="$t('risposta')"
                                                                outlined
                                                                rows="2"></v-textarea>
                                                </v-col>

                                                <v-col class="col-1" style="min-height: 2px"></v-col>

                                                <v-col class="col-11 pt-0">
                                                    <v-textarea v-model="q.notes" auto-grow
                                                                background-color="grey lighten-2"
                                                                dense
                                                                :label="$t('note')" outlined rows="2"></v-textarea>
                                                </v-col>

                                            </div>

                                            <div class="row" v-if="q.answerKind === 'text'">

                                                <v-col class="col-11 pt-0">
                                                    <v-textarea v-model="q.answer" auto-grow dense
                                                                :label="$t('risposta')"
                                                                outlined
                                                                rows="2"></v-textarea>
                                                </v-col>


                                                <v-col class="col-1" style="min-height: 2px"></v-col>

                                                <v-col class="col-11 pt-0">
                                                    <v-textarea v-model="q.notes" auto-grow
                                                                background-color="grey lighten-2"
                                                                dense
                                                                :label="$t('note')" outlined rows="2"></v-textarea>
                                                </v-col>

                                            </div>

                                            <div class="row" v-if="q.answerKind === 'multiple'">

                                                <v-col class="col-11 pt-0">

                                                    <v-checkbox v-for="(n, idx) in q.answerOptions"
                                                                :key="n"
                                                                v-model="q.answer[idx].value"
                                                                :color="section.color"
                                                                :label="n"
                                                    ></v-checkbox>

                                                </v-col>

                                                <v-col class="col-1" style="min-height: 2px"></v-col>

                                                <v-col class="col-11 pt-0">
                                                    <v-textarea v-model="q.text_answer" auto-grow dense
                                                                :label="$t('risposta')"
                                                                outlined
                                                                rows="2"></v-textarea>
                                                </v-col>


                                                <v-col class="col-1" style="min-height: 2px"></v-col>

                                                <v-col class="col-11 pt-0">
                                                    <v-textarea v-model="q.notes" auto-grow
                                                                background-color="grey lighten-2"
                                                                dense
                                                                :label="$t('note')" outlined rows="2"></v-textarea>
                                                </v-col>

                                            </div>

                                        </div>

                                    </v-row>
                                </v-card>
                                <v-row>
                                    <v-spacer/>
                                    <v-col class="col-4" style="text-align: right">
                                        <v-btn
                                            v-if="e6 > 1"
                                            color="grey--text"
                                            text
                                            @click="e6 = index"
                                        >
                                            {{ $t('precedente') }}
                                        </v-btn>
                                        <v-btn
                                            v-if="e6 !== at.data.sections.length"
                                            color="primary"
                                            text
                                            @click="e6 = index + 2"
                                        >
                                            {{ $t('prossimo') }}
                                        </v-btn>
                                        <v-btn
                                            v-if="isNew == true"
                                            color="primary"
                                            text
                                            @click="saveAudit"
                                        >
                                            {{ $t('save') }}
                                        </v-btn>

                                        <v-btn
                                            v-if="isNew == false"
                                            color="primary"
                                            text
                                            @click="resaveAudit"
                                        >
                                            {{ $t('save') }}
                                        </v-btn>

                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper>
                    </div>
                </v-card-text>
            </v-card>
        </div>

        <div v-if="tabAT == 1 && at !== undefined && at.id !== undefined">
            <HistoryCard :audit="at"/>
        </div>

        <div v-if="tabAT == 2">
            <v-row>
                <v-col class="col-12 pa-5">
                    <v-card class="elevation-0">
                        <v-card-text v-if="isNew == false" class="pb-0 mb-0">
                            <h2> {{ at.company_name }}</h2>
                            <h4 class="overline"> {{ $t('audit_from_date') }} {{ dataAggiornamento }}</h4>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col class="col-12 pa-8 white " id="radar_chart_container">
                    <ApexRadarSingle :chart-data="loadRadarChart(at)" :label-size="100" :big-labels="true"
                                     :options="radarChartOptionsSmall"></ApexRadarSingle>
                </v-col>

                <v-col class="col-12 pa-8 white mb-3 ml-3">
                    <v-btn
                        color="primary"
                        text
                        @click="exportChart"
                    >
                        {{ $t('save_chart') }}
                    </v-btn>
                </v-col>

            </v-row>
        </div>

        <div v-if="tabAT == 3 && at !== undefined && at.id !== undefined">
            <ListDownload :kind="'audit'" :name="at.id"/>
        </div>

        <div v-if="tabAT == 4 && at !== undefined && at.id !== undefined">
            <ReportCard :report="at.report" :audit="at"/>
        </div>

        <v-dialog v-model="showHelpDialog" width="600">
            <v-card>
                <v-card-title>
                    {{ $t('maggiori_informazioni') }}
                </v-card-title>
                <v-card-subtitle>
                    <div class="mt-4">
                        {{ helpMessage }}
                    </div>
                </v-card-subtitle>
                <v-card-actions>
                    <v-btn text @click="() => {showHelpDialog = false}">{{ $t('chiudi') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import ChartRadarSingle from '@/components/ChartRadarSingle.vue'
import ApexRadarSingle from '@/components/ApexChartRadarSingle.vue'
import ChartBar from '@/components/ChartBar.vue'
import ListDownload from '@/components/ListDownload.vue'
import graph_calcs from "@/helpers/graph_calcs";
import {DateTime} from "luxon/build/es6/luxon";
import ReportCard from "@/components/ReportCard";
import {getColor} from "@/helpers/get_color";
import HistoryCard from "@/components/HistoryCard";
import Vue from "vue";
import {ordinalToGregorian} from "luxon/src/impl/conversions";

export default {
    name: 'ManageAudit',
    data: function () {
        return {
            isNew: true,
            tabAT: 0,
            tabATSection: 0,
            searchQuery: '',
            searchResult: [],
            searchHeaders: [],

            showSaveButton: false,
            enableShowSaveButton: false,

            // Data
            companies: [],
            audittemplates: [],

            audittemplateIndex: null,
            companyIndex: null,

            // Audit
            audit: {name: '', date: (new Date()).toISOString().split('T')[0]},
            company: {},
            at: {},
            e6: 1,

            radarChartOptionsSmall: {
                legend: {
                    display: true
                },
            },

            helpMessage: null,
            showHelpDialog: false,
        }
    },
    watch: {
        at: {
            handler(newVal, oldVal) {
                if (Object.keys(oldVal).length == 0) {
                    // Do nothing at loading
                } else {
                    if (this.enableShowSaveButton == true) {
                        this.showSaveButton = true
                    }
                }
            },
            deep: true
        }
    },
    components: {
        ReportCard,
        ChartRadarSingle, ChartBar, ListDownload, ApexRadarSingle,
        HistoryCard
    },
    computed: {
        dataAggiornamento: function () {
            let dt = DateTime.fromISO(this.at.date);
            return dt.toFormat("dd/LL/yyyy");
        }
    },
    methods: {

        saveAudit() {
            if (this.audit.name == '') {
                alert('Name cannot be empty')
                return
            }
            this.audit = {
                company_id: this.companies[this.companyIndex].id,
                company_name: this.companies[this.companyIndex].name,
                audit_id: this.at.id,
                workspace: {code: this.$store.state.workspace},
                data: this.at,
                name: this.audit.name,
                date: this.audit.date
            }
            this.$store.dispatch('apply', {
                kind: 'audit',
                data: this.audit,
                cb: function (err, response) {

                    if (err == null) {
                        this.showSaveButton = false

                        this.$store.state.selectedAudit = response.data.response.id;

                        this.fetch();

                        this.isNew = false;

                        this.$toast(this.$t('audit_saved'))

                    } else {
                        alert('Error in save Audit')
                    }

                }.bind(this)
            })
        },

        resaveAudit() {
            console.log(this.at)
            this.audit = {
                id: this.at.id,
                company_id: this.at.company_id,
                company_name: this.at.company_name,
                audit_id: this.at.audit_id,
                workspace: this.at.workspace,
                data: {
                    data: this.at.data,
                    created_at: this.originalAt.data.created_at,
                    name: this.originalAt.data.name,
                    version: this.originalAt.data.version,
                    workspace: this.originalAt.data.workspace,
                },
                report: this.at.report,
                name: this.at.name,
                date: this.at.date
            }
            this.$store.dispatch('apply', {
                kind: 'audit',
                data: this.audit,
                cb: function () {
                    this.showSaveButton = false;

                    this.$toast(this.$t('audit_salvato'))

                }.bind(this)
            })
        },

        exportAudit() {

            this.$store.dispatch('export_doc', {
                body: {
                    audit_id: this.at.id,
                    lang: this.$i18n.locale
                },
                cb: function (err, response) {
                    const now = DateTime.now();

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.at.company_name + "-report audit-" + now.toISODate() + ".docx"); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                }.bind(this)
            })

        },

        exportAuditPDF() {

            this.$store.dispatch('export_pdf', {
                body: {
                    audit_id: this.at.id,
                    lang: this.$i18n.locale
                },
                cb: function (err, response) {
                    const now = DateTime.now();

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.at.company_name + "-report audit-" + now.toISODate() + ".pdf"); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                }.bind(this)
            })

        },

        exportAuditXLSX() {

            this.$store.dispatch('export_xlsx', {
                body: {
                    audit_id: this.at.id,
                    lang: this.$i18n.locale
                },
                cb: function (err, response) {
                    const now = DateTime.now();

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.at.company_name + "-assessment-" + now.toISODate() + ".xlsx"); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                }.bind(this)
            })

        },

        exportChart() {

            // Scarichiamo l'SVG come fosse una PNG

            let svgNode = document.querySelectorAll("#radar_chart_container svg");
            svgNode = svgNode[0];
            let img = new Image();
            let serializer = new XMLSerializer();
            let svgStr = serializer.serializeToString(svgNode);

            img.onload = () => {
                // You could also use the actual string without base64 encoding it:
                //img.src = "data:image/svg+xml;utf8," + svgStr;

                let canvas = document.createElement("canvas");
                const now = DateTime.now();

                let w = svgNode.clientWidth;
                let h = svgNode.clientHeight;

                canvas.width = w;
                canvas.height = h;

                const ctx = canvas.getContext("2d");

                ctx.beginPath();
                ctx.rect(0, 0, w, h);
                ctx.fillStyle = "white";
                ctx.fill();

                ctx.drawImage(img, 0, 0, w, h);

                ctx.fillStyle = "black";
                ctx.font = "25px Arial";
                ctx.fillText(this.at.company_name + " - " + now.toISODate(), 10, 25);

                let imgURL = canvas.toDataURL("image/png");

                let dlLink = document.createElement('a');
                dlLink.download = this.at.company_name + "-grafico-" + now.toISODate() + ".png"
                dlLink.href = imgURL;
                dlLink.dataset.downloadurl = ["image/png", dlLink.download, dlLink.href].join(':');

                document.body.appendChild(dlLink);
                dlLink.click();
                document.body.removeChild(dlLink);
            };

            img.src = 'data:image/svg+xml;base64,' + window.btoa(svgStr);

        },

        getColor(index) {
            return getColor(index);
        },

        loadRadarChart(ca) {
            const index = 0;
            const {score, dd, radarDataForAudit} = graph_calcs(ca);
            this.score = score;
            radarDataForAudit.datasets[0] = {
                label: ca.data.name,
                data: dd,
                borderColor: this.getColor(index),
                backgroundColor: this.getColor(index),
            }
            return radarDataForAudit;
        },

        loadBarChart(ca) {
            let index = 3
            this.score = 0
            let radarDataForAudit = {labels: [], datasets: []}
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            let dd = []
            ca.data.sections.forEach(function (section) {
                let _data = section.questions.map(function (q) {
                    if (q.answerScoreOverride !== undefined) {
                        return parseInt(q.answerScoreOverride)
                    } else {
                        return parseInt(q.answerScore[q.answer])
                    }
                })

                if (_data.length > 0) {
                    _data = _data.reduce(reducer)
                    this.score += parseInt(_data)
                    radarDataForAudit.labels.push(section.name)
                    dd.push(_data)
                }
            }.bind(this))
            radarDataForAudit.datasets[0] = {
                label: ca.name,
                data: dd,
                borderColor: this.getColor(index),
                backgroundColor: this.getColor(index),
            }
            return radarDataForAudit
        },

        fetch() {
            this.$store.dispatch('describeAudit', {

                kind: 'audit',
                audit_id: this.$store.state.selectedAudit,

                cb: function (err, data) {
                    if (err == null && data.response.length == 1) {
                        this.at = data.response[0]
                        this.originalAt = data.response[0]
                        this.at.data = data.response[0].data.data

                        this.at.data.sections.forEach(function (s, index) {
                            if (s.color == undefined) {
                                s.color = this.getColor(index)
                            }
                        }.bind(this))
                        setTimeout(function () {
                            this.enableShowSaveButton = true
                        }.bind(this), 500)

                    }
                }.bind(this)
            })
        },

        fetchData() {
            this.$store.dispatch('get', {

                kind: 'company',
                data: {
                    workspace: this.$store.state.workspace,
                },
                cb: function (err, data) {
                    if (err == null) {
                        this.companies = data.response.map(function (a, index) {
                            a.index = index
                            if (this.company !== undefined && a.id == this.company.id) {
                                this.companyIndex = index
                            }
                            return a
                        }.bind(this))

                    }
                }.bind(this)
            })
            this.$store.dispatch('get', {

                kind: 'audittemplate',
                data: {
                    workspace: this.$store.state.workspace,
                },
                cb: function (err, data) {
                    if (err == null) {
                        this.audittemplates = data.response.map((a, index) => {
                            a.index = index
                            a.value = a.name + ' ' + a.version
                            a.text = a.name + ' ' + a.version
                            return a
                        })
                    }
                }.bind(this)
            })
        },

        startAudit() {
            if (this.audittemplateIndex == null || this.audittemplates[this.audittemplateIndex] == undefined || this.audittemplates[this.audittemplateIndex].name == '') {
                alert("E' necessario selezionare un modello per l'audit")
                return
            }
            if (this.audittemplates[this.audittemplateIndex].version == '') {
                return
            }
            this.$store.dispatch('describeAuditTemplate', {

                kind: 'audittemplate',
                name: this.audittemplates[this.audittemplateIndex].name,
                version: this.audittemplates[this.audittemplateIndex].version,

                cb: function (err, data) {
                    if (err == null && data.response.length == 1) {
                        this.at = data.response[0]
                        this.at.data.sections.forEach(function (s, index) {
                            if (s.color == undefined) {
                                s.color = this.getColor(index)
                            }
                            // Uncomment for default response
                            s.questions.forEach((q) => {
                                if (q.answerKind === 'radio') {
                                    q.answer = q.defaultOption
                                }

                                if (q.answerKind === 'radio-specify-score') {
                                    q.answer = q.defaultOption
                                }

                                if (q.answerKind === 'multiple') {
                                    q.answer = q.defaultOption
                                }

                            })
                        }.bind(this))

                    }
                }.bind(this)
            })
        },

        isQuantitative(q) {
            if ((q.answerKind === "radio" || q.answerKind === "radio-specify-score") && parseFloat(q.answerScoreWeight) > 0) {
                return "quantitative_question"
            }
            return "";
        },

        toggleOption(option) {
            option.value = !option.value;
        },

        changeQuestionValue(sectionIndex, questionIndex, value) {
            // Attenzione: in questo caso 1 è Si e 0 è No!

            const q = JSON.parse(JSON.stringify(this.at.data.sections[sectionIndex].questions[questionIndex]));

            if (value === 1) {
                q.answer = 1;
                q.answerScoreOverride = 0;
            } else {
                q.answerScoreOverride = 1;
            }

            Vue.set(this.at.data.sections[sectionIndex].questions, questionIndex, q);
        },

        updateScoreValue(sectionIndex, questionIndex, value) {
            const q = JSON.parse(JSON.stringify(this.at.data.sections[sectionIndex].questions[questionIndex]));

            q.answerScoreOverride = value;
            if (value === 0) {
                q.answer = 1;
            } else {
                q.answer = 0;
            }

            Vue.set(this.at.data.sections[sectionIndex].questions, questionIndex, q);
        }

    },
    mounted() {
        if (this.$route.params.audit == '__new__') {
            this.$store.state.selectedAudit = 'New'
            if (this.$route.params.company !== undefined) {
                console.log("company from params", this.$route.params.company);
                this.company = this.$route.params.company
            }
            this.fetchData()
        } else {
            try {
                this.isNew = false;

                this.$store.state.selectedAudit = this.$route.params.audit_id;

                this.fetch();
            } catch (err) {
                console.log(err)
            }
        }
    },


}
</script>

<style>
.quantitative_question {
    color: #1976d2;
}
</style>
