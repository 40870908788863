<template>
    <v-container>
        <v-card v-for="f in files" class="mt-2 pt-0 pb-0">
            <v-card-title class="overline pt-0 pb-0" :key="f">
                <v-btn icon @click="downloadFile(f)">
                    <v-icon small>fas fa-download</v-icon>
                </v-btn>
                {{ f }}
                <v-spacer/>
                <v-btn icon @click="deleteFile(f)">
                    <v-icon class="warning--text" small>fas fa-trash</v-icon>
                </v-btn>
            </v-card-title>
        </v-card>
        <v-card class="example-drag mt-2 elevation-0">
            <v-card-title class="overline grey--text pa-12" style="border: 1px dashed grey">
                {{ $t('drop_to_upload')}}
            </v-card-title>
            <div class="upload">
                <ul v-if="filesToUpload.length" class="pt-2">
                    <li v-for="file in filesToUpload" :key="file.id">
                        <span>{{ file.name }}</span> -
                        <span v-if="file.error" class="red--text">{{ file.error }}</span>
                        <span v-else-if="file.success" class="green--text">success</span>
                        <span v-else-if="file.active">active</span>
                        <span v-else></span>
                    </li>
                </ul>
                <FileUpload
                    ref="upload"
                    v-model="filesToUpload"
                    :drop="true"
                    :drop-directory="true"
                    :headers="{'Authorization': 'Bearer ' + $store.state.userToken}"
                    :multiple="true"
                    :post-action="$store.state.apiServer + '/v1/' + $store.state.workspace + '/' + kind + '/' + name + '/upload'"
                    class="btn btn-primary"
                    @input-file="inputFile">
                    <v-btn v-if="filesToUpload.length === 0" class="primary--text" style="cursor: pointer" text>{{ $t("clicca_per_selez_file")}}
                    </v-btn>
                </FileUpload>

                <v-card-actions>
                    <v-btn v-if="filesToUpload.length > 0" class="primary--text" text @click="filesToUpload = []">
                        {{ $t("annulla")}}
                    </v-btn>
                    <v-spacer/>
                    <v-btn v-if="!$refs.upload || !$refs.upload.active" class="warning--text" text
                           @click.prevent="$refs.upload.active = true">
                        <i aria-hidden="true" class="fa fa-arrow-up"></i>
                        {{$t("start_upload")}}
                    </v-btn>
                    <v-btn v-else class="warning--text" text @click.prevent="$refs.upload.active = false">
                        <i aria-hidden="true" class="fa fa-stop"></i>
                        {{$t("stop_upload")}}
                    </v-btn>
                </v-card-actions>
            </div>

        </v-card>
    </v-container>
</template>
<script>
import FileUpload from 'vue-upload-component'

export default {
    name:       'ListDownload',
    props:      ['kind', 'name'],
    components: {FileUpload},
    data:       function () {
        return {
            files:         [],
            fetchInterval: null,
            filesToUpload: []
        }
    },
    watch:      {
        '$refs.upload'(to, from) {
            console.log(to, this.$refs.upload.uploaded)
            if (this.$refs.upload.uploaded) {
                this.fetch()
            }
        }
    },
    methods:    {
        inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                // Add file
            }

            if (newFile && oldFile) {
                // Update file

                // Start upload
                if (newFile.active !== oldFile.active) {
                    console.log('Start upload', newFile.active, newFile)

                    // max size
                    if (newFile.size > 10000 * 1024) {
                        newFile = this.$refs.upload.update(newFile, {error: 'size'})
                    }
                }

                // Upload progress
                if (newFile.progress !== oldFile.progress) {
                    console.log('progress', newFile.progress, newFile)
                }

                // Upload error
                if (newFile.error !== oldFile.error) {
                    console.log('error', newFile.error, newFile)
                }

                // Uploaded successfully
                if (newFile.success !== oldFile.success) {
                    if (newFile.success === true) {
                        this.fetch()
                    }
                    console.log('success', newFile.success, newFile)
                }
            }

            if (!newFile && oldFile) {
                // Remove file

                // Automatically delete files on the server
                if (oldFile.success && oldFile.response.id) {
                    // $.ajax({
                    //   type: 'DELETE',
                    //   url: '/file/delete?id=' + oldFile.response.id,
                    // });
                }
            }

            // Automatic upload
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            }

        },
        fetch() {
            this.$store.dispatch('listFiles', {
                name:      this.name,
                kind:      this.kind,
                cb:        function (err, data) {
                    this.files = data.data
                }.bind(this)
            })
        },
        downloadFile(f) {
            this.$store.dispatch('downloadFile', {
                name:      this.name + '-@-' + f,
                kind:      this.kind,
                cb:        function (err, data) {

                }.bind(this)
            })
        },
        deleteFile(f) {
            this.$store.dispatch('deleteFile', {
                name:      this.name + '-@-' + f,
                kind:      this.kind,
                cb:        function (err, data) {
                    this.fetch()
                }.bind(this)
            })
        }
    },
    mounted() {

        this.fetch()
    }
}
</script>
