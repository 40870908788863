<template>
    <v-card class="pa-2" outlined>
        <v-card-text class="pb-0 mb-0">
            <v-row>
                <v-col class="col-4">
                    <h2> {{ audit.company_name }}</h2>
                    <h4 class="overline"> {{ $t("audit_from_date") }} {{ dataAggiornamento }}</h4>
                </v-col>

                <v-col class="col-md-8 col-8" style="text-align: right">

                    <v-btn
                        color="primary"
                        text
                        @click="handleInitializeReport"
                    >
                        {{ $t("precompila_report") }}
                    </v-btn>


                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                    color="primary"
                                    text
                                    style="margin-right: 100px"
                                    v-bind="attrs"
                                    v-on="on"
                            >
                                {{ $t("export") }}
                            </v-btn>
                        </template>
                        <v-list>

                            <v-list-item @click="exportReport('trentino')">
                                <v-list-item-title>{{ $t("esporta_formato") }} Trentino Sviluppo</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="exportReport('een')">
                                <v-list-item-title>{{ $t("esporta_formato") }} EEN</v-list-item-title>
                            </v-list-item>

                        </v-list>
                    </v-menu>

                    <v-btn
                            color="primary"
                            dark
                            style="position: fixed;right: 38px;z-index: 10"
                            @click="saveReport"
                    >
                        {{ $t("save") }}
                    </v-btn>

                </v-col>

            </v-row>
        </v-card-text>

        <v-card-text class="pb-0 mb-0">
            <v-row>
                <div v-for="section in sections" class="col-12">

                    <v-col class="col-12">
                        <b class="overline"> {{ getLabel(section) }}</b>

                        <div v-if="section === 'paesi'">
                            <v-select
                                    v-model="$data.report_data.paesi"
                                    :items="countries_list"
                                    :menu-props="{ maxHeight: '400' }"
                                    :label="$t('seleziona')"
                                    multiple
                                    :hint="$t('seleziona_paesi')"
                                    persistent-hint
                            ></v-select>
                        </div>
                        <div v-else-if="indicatori_economici.indexOf(section) >= 0  ">

                            <v-row v-if="$data.report_data[section]">
                                <v-col class="col-4">
                                    <v-text-field
                                            v-model="$data.report_data[section][0]"

                                            color="blue"
                                            dense
                                            outlined></v-text-field>
                                </v-col>

                                <v-col class="col-4">
                                    <v-text-field
                                            v-model="$data.report_data[section][1]"

                                            color="blue"
                                            dense
                                            outlined></v-text-field>
                                </v-col>

                                <v-col class="col-4">
                                    <v-text-field
                                            v-model="$data.report_data[section][2]"
                                            class="col-4"
                                            color="blue"
                                            dense
                                            outlined></v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                        <div v-else-if="section === 'punti_forza' || section === 'punti_debolezza' ">

                            <v-select
                                    v-model="$data.report_data[section]"
                                    :items="audit_sections_uppercase"
                                    :menu-props="{ maxHeight: '400' }"
                                    :label="$t('seleziona')"
                                    multiple
                                    :hint="$t('seleziona')"
                                    persistent-hint
                            ></v-select>
                        </div>
                        <div v-else>
                            <VueEditor
                                    v-model="$data.report_data[section]"
                            ></VueEditor>
                        </div>

                    </v-col>

                </div>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import countries_it from '../countries_it.json';
import {DateTime} from "luxon/build/es6/luxon";
import graph_calcs from "@/helpers/graph_calcs";
import {VueEditor} from "vue2-editor";

// Prepariamo l'elenco delle countries per la tendina
let countriesIt = [];
Object.keys(countries_it).forEach((cKey) => {

    let countryInfo = countries_it[cKey];

    if (!countryInfo.hasOwnProperty("english_country_name")) {
        return;
    }

    if(!countryInfo.english_country_name || countryInfo.english_country_name === "") {
        return;
    }

    countriesIt.push({
        value: countryInfo.iso3361_2_characters,
        text:  countryInfo.english_country_name.toUpperCase()
    })

})

countriesIt = countriesIt.sort((a, b) => {
    return a.text > b.text ? 1 : -1
})

const defaults = {
    "risultati_prodotto":        "B",
    "risultati_mercati":         "C",
    "risultati_strategie":       "D",
    "risultati_risorse_umane":   "E",
    "risultati_processi":        "F",
    "risultati_export_digitale": "G",
    "risultati_comunicazione":   "H",
};

export default {
    name:       'ReportCard',
    props:      ['report', 'audit'],
    components: {
        VueEditor
    },
    data:       function () {
        return {

            sections: [
                "premessa",
                "descrizione_azienda",
                "paesi",
                "situazione_economica",
                "anni_indicatori_economici",
                "RICAVI",
                "MOL",
                "UTILE",
                "PN",
                "TOTALE_ATTIVO",
                "PFN",
                "risultati_prodotto",
                "risultati_mercati",
                "risultati_strategie",
                "risultati_risorse_umane",
                "risultati_processi",
                "risultati_export_digitale",
                "risultati_comunicazione",
                "punti_forza",
                "punti_debolezza",
                "sintesi_assessmet",
                "proposta_attivita",
            ],

            audit_sections: [],

            indicatori_economici: ['anni_indicatori_economici', 'MOL', 'UTILE', 'RICAVI', 'PFN', 'PN', 'TOTALE_ATTIVO'],
            report_data:          {}

        };
    },
    mounted() {
        this.initializeReport();
        this.audit_sections = this.audit.data.sections.map((section) => {
            return section.name;
        })
    },
    computed: {
        countries_list: function () {
            return countriesIt;
        },

        dataAggiornamento: function () {
            let dt = DateTime.fromISO(this.audit.date);
            return dt.toFormat("dd/LL/yyyy");
        },
        audit_sections_uppercase: function (){
            return this.audit_sections.map((section) => {
                return section.toUpperCase();
            })
        }

    },
    watch:    {
        report: {
            handler: function () {
                this.initializeReport();
            }
        },
        audit:  {
            handler: function () {
                this.initializeReport();
            }
        }
    },
    methods:  {

        getLabel(section_name) {

            if (section_name === "premessa") return this.$t("premessa");
            if (section_name === "descrizione_azienda") return this.$t('descrizione_azienda');
            if (section_name === "paesi") return this.$t('paesi_dove_esporta');
            if (section_name === "situazione_economica") return this.$t('situazione_economica');
            if (section_name === "risultati_prodotto") return this.$t('risultati_audit') + " - " + this.$t('prodotto');
            if (section_name === "risultati_mercati") return this.$t('risultati_audit') + " - " + this.$t('mercati');
            if (section_name === "risultati_strategie") return this.$t('risultati_audit') + " - " + this.$t('strategie_export');
            if (section_name === "risultati_risorse_umane") return this.$t('risultati_audit') + " - " + this.$t('risorse_umane');
            if (section_name === "risultati_processi") return this.$t('risultati_audit') + " - " + this.$t('processi');
            if (section_name === "risultati_export_digitale") return this.$t('risultati_audit') + " - " + this.$t('export_digitale');
            if (section_name === "risultati_comunicazione") return this.$t('risultati_audit') + " - " + this.$t('comunicazione_promozione');
            if (section_name === "sintesi_assessmet") return this.$t('sintesi_assessment');
            if (section_name === "proposta_attivita") return this.$t('proposta_attivita');
            if (section_name === "anni_indicatori_economici") return this.$t('anni');
            if (section_name === "MOL") return this.$t('spiegazione_mol');
            if (section_name === "RICAVI") return this.$t('spiegazione_ricevi');
            if (section_name === "PFN") return this.$t('spiegazione_posizione_finanziaria');
            if (section_name === "PN") return this.$t('spiegazione_patrimonio');
            if (section_name === "TOTALE_ATTIVO") return this.$t('spiegazione_totale_attivo');
            if (section_name === "UTILE") return this.$t('spiegazione_utile');
            if (section_name === "punti_forza") return this.$t('punti_forza');
            if (section_name === "punti_debolezza") return this.$t('ambiti_miglioramento');
            return "";
        },

        initializeReport() {
            if (this.report) {
                this.report_data = this.report;
            } else {
                this.forceInitializeReport();
            }

        },

        handleInitializeReport(){
            confirm(this.$t('confirm_precompila_report')) && this.forceInitializeReport();
        },

        forceInitializeReport(){
            let newData = {};

            // Prendiamo le risposte dell'audit e le mettiamo
            this.sections.forEach((section_name) => {

                if (section_name === "paesi") {
                    newData.paesi = [];
                    return;
                }

                if (this.indicatori_economici.indexOf(section_name) >= 0) {
                    newData[section_name] = [0, 0, 0];
                    return;
                }

                if (defaults.hasOwnProperty(section_name)) {
                    let default_questions = defaults[section_name];
                    let default_text = "";

                    this.audit.data.sections.forEach((sectionData) => {
                        if (sectionData.section === default_questions) {
                            sectionData.questions.forEach((questionData) => {

                                if (default_text !== "") {
                                    default_text += "\n\n\n";
                                }

                                let output = "<b>" + questionData.question + ":</b> ";
                                if (questionData.answerKind === "text") {
                                    output += questionData.answer;
                                } else if (questionData.answerKind === "radio") {
                                    if (questionData.answerOptions && questionData.answerOptions.hasOwnProperty(questionData.answer)) {
                                        output += questionData.answerOptions[questionData.answer];
                                    }
                                } else if (questionData.answerKind === "multiple") {
                                    let result = "";
                                    questionData.answer.forEach((a) => {
                                        if (a.value === true) {
                                            if (result !== "") {
                                                result += ", ";
                                            }
                                            let index = parseInt(a.id, 10);
                                            result += questionData.answerOptions[index - 1];
                                        }
                                    })

                                    output += result;
                                }

                                if (questionData.notes && questionData.notes.length > 0) {
                                    output += "\nNota: " + questionData.notes;
                                }

                                if (questionData.text_answer && questionData.text_answer.length > 0) {
                                    output += "\nRisposta: " + questionData.text_answer;
                                }

                                default_text += output;
                            })
                        }
                    })


                    newData[section_name] = default_text;

                    return;
                }

                // Default
                newData[section_name] = "";

            })

            this.report_data = newData;
        },

        exportReport: function (template) {

            const {score, dd, radarDataForAudit} = graph_calcs(this.audit);

            this.$store.dispatch('export_report', {
                body: {
                    audit_id:          this.audit.id,
                    score:             score,
                    dd:                dd,
                    template:          template,
                    radarDataForAudit: radarDataForAudit,
                    lang:              this.$i18n.locale
                },
                cb:   function (err, response) {
                    const now = DateTime.now();

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.audit.company_name + "-report_finale-" + now.toISODate() + ".pdf"); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                }.bind(this)
            })
        },

        saveReport: function () {

            console.log(this.report_data, "this.report_data");

            let errors ="";
            // Verifichiamo se tutti i campi obbligatori sono presenti
            if(!this.report_data.paesi || this.report_data.paesi.length === 0){
                errors += this.$t('paesi_dove_esporta') + "<br />";
            }

            if(errors.length > 0){
                let message = this.$t('errore_campi_obbligatori') + ":<br />&nbsp;<br />" + errors;
                this.$toast(message);
                return;
            }

            const auditData = {
                id:           this.audit.id,
                company_id:   this.audit.company_id,
                company_name: this.audit.company_name,
                audit_id:     this.audit.audit_id,
                workspace:    this.audit.workspace,
                data:         {
                    data:       this.audit.data,
                    created_at: this.audit.data.created_at,
                    name:       this.audit.data.name,
                    version:    this.audit.data.version,
                    workspace:  this.audit.data.workspace,
                },
                name:         this.audit.name,
                date:         this.audit.date,
                report:       this.report_data,
            }

            this.$store.dispatch('apply', {
                kind:      'audit',
                data:      auditData,
                cb:        function () {
                    this.showSaveButton = false;
                    this.$toast(this.$t('resport_saved'))
                }.bind(this)
            })
        }

    }
}
</script>

<style>
.ql-container {
    max-height: calc(100vh - 200px);
    overflow: auto;
}
</style>
