<template>
    <v-container class="pa-0" fluid>
        <v-card class="brown lighten-1 white--text pa-0 ma-0 elevation-0" dense style="border-radius: 0px">
            <v-card-title class="pt-1 pb-1 overline"> {{ $t('confronto')}}
                <v-spacer/>
                <v-btn v-if="started == true" color="white" text @click="started = false"> {{ $t('nuovo')}}</v-btn>
            </v-card-title>
        </v-card>
        <v-card v-if="started == false" outlined class="pa-2">
            {{ $t('confronto_intro')}}
            <v-card-text>
                <v-select
                    v-model="selectedAudits"
                    :items="audits"
                    :menu-props="{ maxHeight: '400' }"
                    :hint="$t('seleziona_hint')"
                    :label="$t('seleziona')"
                    multiple
                    persistent-hint
                ></v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn v-if="enable == true" color="primary" text @click="start">{{ $t('inizia_confronto')}}</v-btn>
            </v-card-actions>
        </v-card>


        <div v-if="started == true">
            <v-row class="mt-0 pt-0">

                <v-col class="col-12 ma-1 pt-0">
                    <v-card class="pa-12 pt-1">
                        <ApexRadarSingle
                            :label-size="100"
                            :big-labels="true"
                            :chart-data="computeRadar()"
                            :options="radarChartOptionsSmall"></ApexRadarSingle>
                    </v-card>
                </v-col>
                <v-col class="col-12">
                    <v-card class="pa-12">
                        <chart-bar
                            :height="100"
                            :chart-data="computeRadar()"
                            :options="barChartOptions"></chart-bar>
                    </v-card>
                </v-col>

                <div v-for="(section, indexSection) in audits[0].data.data.sections" class="col-12">
                    <v-col class="col-12">
                        <v-card class="pa-12">
                            <div class="">
                                <div class="section_name">{{ section.name }}</div>

                                <div v-for="(question, indexQuestion) in section.questions">

                                    <div class="question col-12">
                                        {{ question.question }}
                                    </div>

                                    <div class="row">
                                        <v-col :class="'col-' + Math.floor(12 / selectedAudits.length)"
                                               v-for="index in selectedAudits">

                                            {{ getQuestionAnswer(index, indexSection, indexQuestion) }}

                                        </v-col>
                                    </div>

                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </div>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import ChartRadarSingle from '@/components/ChartRadarSingle.vue'
import ChartBar from '@/components/ChartBar.vue'
import ApexRadarSingle from '@/components/ApexChartRadarSingle.vue'
import graph_calcs from "@/helpers/graph_calcs";
import {getColor} from "@/helpers/get_color";

export default {
    name:       'Match',
    components: {
        ChartRadarSingle, ChartBar, ApexRadarSingle
    },
    data:       function () {
        return {
            audits:         [],
            selectedAudits: [],

            enable:  false,
            started: false,

            // Charts
            radarChartOptionsSmall: {
                legend:  {
                    display: true
                },
                plugins: {
                    tooltip: {
                        enabled: false
                    }
                }
            },

            barChartOptions: {
                tooltips: {
                    enabled: false
                },
                scales:   {
                    xAxes: [{
                        ticks: {
                            min: 0,
                            max: 10
                        }
                    }]
                }
            },

            scores: {}

        }
    },

    mounted() {
        this.fetchAudits()
    },
    watch:   {
        selectedAudits(newVal, oldVal) {
            this.enable = newVal.length > 1 ? true : false
        }
    },
    methods: {
        start() {
            this.computeRadar()
        },

        getQuestionAnswer(index, indexSection, indexQuestion) {
            const question = this.audits[index].data.data.sections[indexSection].questions[indexQuestion];
            if (!question) return "";

            if (question.answerKind === "text") {

                let output = question.answer;
                if(question.notes){
                    output += " - " + question.notes;
                }

                return output;

            } else if (question.answerKind === "radio" || question.answerKind === "radio-specify-score") {

                const index = parseInt(question.answer, 10)
                let score = question.answerScore[index];
                if(question.answerScoreOverride){
                    score = question.answerScoreOverride;
                }
                if(!score) score = 0;

                if (question.answerOptions && Array.isArray(question.answerOptions) && question.answerOptions.length > index) {
                    let output = question.answerOptions[index] + " - (" + score + ")";
                    if(question.notes){
                        output += " - " + question.notes;
                    }
                    return output;
                }

            } else if (question.answerKind === "multiple") {

                let result = "";
                let score = question.answerScore[index];
                if(question.answerScoreOverride){
                    score = question.answerScoreOverride;
                }
                if(!score) score = 0;

                question.answer.forEach((a) => {
                    if (a.value === true) {
                        if (result !== "") {
                            result += ", ";
                        }
                        let index = parseInt(a.id, 10);
                        result += question.answerOptions[index - 1];
                    }
                })

                let output = result + " - (" + score + ")";
                if(question.notes){
                    output += " - " + question.notes;
                }

                return output;

            }

        },
        computeRadar() {
            let index = 0;

            let radarData = null;

            for (let i = 0; i < this.selectedAudits.length; i++) {
                const saIndex = this.selectedAudits[i];
                let ca        = this.audits[saIndex];

                const {score, dd, radarDataForAudit} = graph_calcs(ca);
                if (i === 0) {
                    radarData = radarDataForAudit;
                }
                radarData.datasets.push({
                    label:           ca.company_name + ' - '  + ca.date.split('T')[0],
                    data:            dd,
                    borderColor:     this.getColor(index),
                    backgroundColor: this.getColor(index),
                })

                this.scores[ca.company_name + ' - ' +  + ca.date.split('T')[0]] = score;

                index += 1;
            }

            this.started = true;
            return radarData;
        },

        getColor(index) {
            return getColor(index);
        },
        fetchAudits() {
            this.$store.dispatch('get', {

                kind:      'audit',
                data:      {
                    workspace: this.$store.state.workspace,
                },

                cb: function (err, data) {
                    if (err == null) {
                        if (data.response.length > 0) {
                            this.audits = data.response
                            this.audits.map(function (a, idx) {
                                a.text  = a.company_name + ' - ' + a.name + ' - ' + a.date.split('T')[0]
                                a.value = idx
                            })
                        }

                    }
                }.bind(this)
            })
        }
    },

}
</script>

<style>
.section_name {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
}

.question {
    text-align: center;
    font-weight: bold;
}
</style>
