<template>
    <v-card style="height: 250px" @click="goTo(c)">
        <div style="height: 100px;">
            <v-img v-if="c.data.link === undefined" :src="getImageUrl()" style="max-height: 100px"></v-img>
            <v-img v-else :src="c.data.link" style="max-height: 100px"></v-img>
        </div>
        <v-card-title class="overline"> {{ c.name }}</v-card-title>
        <v-card-subtitle> {{ getRegionNameFromCode(c.country, c.region) }}</v-card-subtitle>
        <v-card-subtitle> {{ getCountryNameFromCode(c.country) }}</v-card-subtitle>

    </v-card>
</template>

<script>

import factory_img from '../assets/factory.png';
import {getColor} from "@/helpers/get_color";
import {DateTime} from "luxon/build/es6/luxon";
import {getCountryNameFromCode, getRegionNameFromCode} from "@/helpers/country_data_utils";

export default {
    name:       'CompanyCard',
    props:      ['c', 'index'],
    components: {},
    data:       function () {
        return {
            radarChartOptionsSmall: {
                legend: {
                    display: false
                },
            },
            score:                  0,
            maxScore:               400
        }
    },
    computed: {
        dataAggiornamento: function(){
            let dt = DateTime.fromISO(this.c.created_at);
            return dt.toFormat("dd/LL/yyyy");
        }
    },
    methods:    {
        getCountryNameFromCode,
        getRegionNameFromCode,

        getImageUrl(){
            if(this.c.image_url){
                return this.c.image_url
            }
            return factory_img;
        },
        goTo(item = '__new__') {
            //delete item.data;
            this.$router.push({name: 'ManageCompany', params: {company_id: item.id}})
        },
        getColor(index, opacity) {
            let hex = getColor(index);
            const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`
            return setOpacity(hex, 0.5)

        }
    }
}
</script>
