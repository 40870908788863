import Vue from 'vue';
import '@fortawesome/fontawesome-free/css/all.css';
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar'

Vue.use(Vuetify, {
    components: {
        VSnackbar,
        VBtn,
        VIcon
    }
})

Vue.use(VuetifyToast);

export default new Vuetify({});
