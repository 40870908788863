//****************************************************************************************************************************
// ATTENZIONE: questo file è in server/src/langs il file in webapp è un link
//****************************************************************************************************************************

export default {
    analisi:                            "Analysis",
    confronto:                          "Comparison",
    confronta_audit:                    "Compare audits/assessments",
    benchmark:                          "Benchmark",
    compara_azienda:                    "Compare company with target group",
    audit_assessment:                   "AUDIT/ASSESSMENT",
    audits:                             "Audits",
    elenco_audit:                       "List of audits/assessments",
    crea_modifica:                      "Create and modify",
    modelli:                            "Templates",
    audit_template:                     "Audit/Assessment template",
    aziende:                            "Companies",
    azienda:                            "Company",
    elenco_aziende:                     "List of companies",
    apri:                               "Open",
    conferma_eliminazione:              "Confirm elimination",
    messaggio_conferma_eliminazione:    "Are you sure you want to delete this element? The operation cannot be undone",
    annulla:                            "Cancel",
    procedi_eliminazione:               "Delete",
    sections:                           "Sections",
    confronta_title:                    "COMPARE SEVERAL VERSIONS OF AUDIT/ASSESSMENT",
    inizia_confronto:                   "Start comparison",
    nuovo:                              "New",
    seleziona_versione_confronto:       "Select versions for comparison",
    seleziona:                          "Select",
    drop_to_upload:                     "Drag file to upload",
    clicca_per_selez_file:              "Click to select file",
    start_upload:                       "Start loading",
    stop_upload:                        "Stop loading",
    audit_from_date:                    "Audit/assessment dated",
    export:                             "Export",
    esporta_formato:                    "Export format",
    save:                               "Save",
    seleziona_paesi:                    "Select countries",
    resport_saved:                      "Report saved",
    selezionare_azienda:                "Select a company:",
    filtro_settore:                     "SECTOR",
    filtro_dipendenti:                  "EMPLOYEES",
    filtro_fatturato:                   "TURNOVER",
    filtro_anno_assessment:             "ASSESSMENT YEAR",
    regioni:                            "REGIONS",
    province:                           "PROVINCES",
    aggiorna:                           "Update",
    nome:                               "Name",
    regione:                            "REGION",
    provincia:                          "PROVINCE",
    aziende_trovate:                    "Companies found",
    tutti:                              "All",
    anno_in_corso:                      "Current year",
    anno_precedente:                    "Previous year",
    due_anni_precedenti:                "Previous two years",
    media_benchmark:                    "BENCHMARK AVERAGE",
    cerca:                              "Find",
    crea_nuovo:                         "Create new",
    nessun_elemento:                    "No { kind } found, start creating one",
    nessun_record:                      "No records { kind } found, start creating one",
    login:                              "Login",
    username:                           "Username",
    password:                           "Password",
    auth_error:                         "Authentication error",
    versioni:                           "Versions",
    grafico:                            "Graph",
    documenti:                          "Documents",
    report:                             "Report",
    modello_audit:                      "Audit/assessment model",
    date_format:                        "Date YY-MM-DD",
    risposta:                           "Reply",
    risposte:                           "Replies",
    note:                               "Notes",
    nota:                               "Note",
    adeguamento_punteggio:              "Score adjustment",
    precedente:                         "Previous",
    prossimo:                           "Next",
    save_chart:                         "Save graph",
    audit_saved:                        "Audit saved",
    modifica_modello_del:               "Change audit template",
    versione:                           "Version",
    aggiungi_prima_sezione:             "Add first section",
    aggiungi_domanda:                   "Add query",
    aggiungi_sezione:                   "Add section",
    inserisci_a:                        "Insert at",
    id_sezione:                         "Section ID",
    nome_sezione:                       "Section name",
    inserisci_alla_posizione:           "Insert in position",
    aggiungi:                           "Add",
    crea:                               "CREATE",
    importa_dati:                       "Import data",
    inserisci_azienda:                  "Enter new company",
    denominazione:                      "Name",
    url_immagine:                       "Image URL",
    via:                                "Street",
    citta:                              "City",
    cap:                                "ZIP CODE",
    nazione:                            "Country",
    nazioni:                            "Countries",
    piva:                               "VAT code",
    telefono:                           "Phone",
    email:                              "Email",
    sito_web:                           "Website",
    rap_legale:                         "Legal representative",
    avvio_attivita:                     "Activity started on",
    n_dipendenti:                       "Number of employees",
    anno_rilevamento_dip:               "Year of employee census",
    ultimo_fatturato:                   "Last turnover",
    anno_rilevamento_fatturato:         "Year of last turnover census",
    codice_ateco:                       "ATECO code",
    codice_nace:                         "NACE code",
    altre_sedi:                         "Other offices",
    struttura_societaria:               "Corporate structure",
    ha_il_cda:                          "Does it have a BoD?",
    cda:                                "BoD",
    ha_comitato_scientifico:            "Does it have a scientific committee?",
    comitato_scientifico:               "Scientific committee",
    iscrizione_albo_imp_artigiane:      "Registration with craft businesses association",
    iscrizione_pmi_innovative:          "Registration as innovative SME",
    data_pmi_innovative:                "Date of registration as innovative SME",
    iscrizione_startup_innovative:      "Registration as innovative startup",
    data_iscrizione_startup_innovative: "Date of registration as innovative startup",
    is_pmi:                             "Is it a SME?",
    is_startup:                         "Is it a startup or a spin-off?",
    tipo_di_spinoff:                    "Type of spin-off",
    spinoff_da:                         "Spin-off derived from",
    partecipazione_altre_scocieta:      "Shares in other companies",
    elenco_altr_partecipate:            "List of other subsidiaries",
    cerca_azienda_crm:                  "Find company in CRM",
    importa:                            "Import",
    info_azienda:                       "Company information",
    documenti_azienda:                  "Company documents",
    elimina:                            "Eliminate",
    nessun_audit:                       "No audit",
    nessun_audit_desc:                  "No audits found for company { company_name }, start creating one.",
    crea_audit:                         "Create audit",
    errore_salvataggio:                 "Error while saving",
    confronto_intro:                    "Compare positioning of companies in the 7 survey areas by selecting one or more audits/assessments from the list",
    seleziona_hint:                     "Select audits/assessments to be compared",
    ultimo_aggiornamento:               "Latest update",
    autore:                             "Author",
    dati_generali:                      "General data",
    contatti:                           "Contacts",
    altre_info:                         "Other information",
    yes:                                "Yes",
    no:                                 "No",
    data:                               "Date",
    referente:                          "Contact",
    sezione:                            "Section",
    domanda:                            "Query",
    punti:                              "Points",
    risposta_testuale:                  "Textual response",
    premessa:                           "Premise",
    descrizione_azienda:                "Company description",
    paesi_dove_esporta:                 "Countries in which the company sells its products",
    situazione_economica:               "Economic and financial position",
    risultati_audit:                    "Audit results",
    prodotto:                           "Product",
    mercati:                            "Markets",
    strategie_export:                   "Export strategies",
    risorse_umane:                      "Human resources",
    processi:                           "Processes",
    export_digitale:                    "Digital export",
    comunicazione_promozione:           "Communication/promotion for export",
    sintesi_assessment:                 "Assessment summary",
    punti_forza:                        "Strengths",
    ambiti_miglioramento:               "Areas for improvement",
    proposta_attivita:                  "Proposed activities",
    indicatori:                         "Indicators",
    indice_redditivita:                 "Profitability (EBITDA/REVENUE)",
    indice_solidita:                    "Capital Strength (NFP/NW)",
    indice_equilibrio:                  "Leverage (NFP/EBITDA)",
    indice_indipendenza:                "Financial Autonomy (NW/TOTAL ASSETS)",
    utile:                              "PROFIT",
    mol:                                "EBITDA",
    ricavi:                             "REVENUE",
    di:                                 "of",
    anni:                               "Years",
    spiegazione_mol:                    "EBITDA",
    spiegazione_ricevi:                 "SALES REVENUES",
    spiegazione_posizione_finanziaria:  "NET FINANCIAL POSITION",
    spiegazione_patrimonio:             "OWNER'S EQUITY",
    spiegazione_totale_attivo:          "TOTAL ASSETS",
    spiegazione_utile:                  "NET INCOME OF THE YEAR",
    assessor:                           "Assessor",
    audit_salvato:                      "Audit saved",
    dati_salvati:                       "Data saved",
}
