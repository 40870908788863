const reducer = (accumulator, currentValue) => accumulator + currentValue;

//****************************************************************************************************************************
// ATTENZIONE: questo file è in server/src/ il file in webapp è un link
//****************************************************************************************************************************
export default function (ca) {
    let score = 0;

    let radarDataForAudit = {labels: [], datasets: []}

    let dd = [];

    let sections;
    if (ca.data.hasOwnProperty("sections")) {
        sections = ca.data.sections;
    } else {
        sections = ca.data.data.sections
    }

    sections.forEach((section) => {
        let totalWeight = 1;
        let _data = section.questions.map(function (q) {
            if (q.answerKind === 'radio' || q.answerKind === "radio-specify-score") {
                let questionWeight = parseFloat(q.answerScoreWeight);

                let questionValue;
                if (q.answerScoreOverride !== undefined && q.answerScoreOverride !== "") {
                    questionValue = parseInt(q.answerScoreOverride);
                } else {
                    questionValue = parseInt(q.answerScore[q.answer]);
                }

                //totalWeight += questionWeight;
                return questionValue * questionWeight;

            } else {
                return 0;
            }
        })

        if (_data.length > 0) {
            _data = _data.reduce(reducer);
            _data = _data / totalWeight;

            if (isNaN(_data)) {
                _data = 0;
            }

            //_data = Math.round(_data);

            score += parseInt(_data);
            radarDataForAudit.labels.push(section.name.toUpperCase());
            dd.push(_data);
        }
    })

    // console.log({
    //     score:             score,
    //     dd:                dd,
    //     radarDataForAudit: radarDataForAudit
    // }, "Graph data");

    return {
        score:             score,
        dd:                dd,
        radarDataForAudit: radarDataForAudit
    }
}
