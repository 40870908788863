<template>
    <v-card class="mx-auto" >
        <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
        </template>

        <div class="pa-0 ma-0" style=" text-align: center">
            <!--<chart-radar-single :chart-data="loadRadarChart(ca)" :options="radarChartOptionsSmall" ></chart-radar-single>-->
            <ApexRadarSingle :chartData="loadRadarChart(ca)" :options="radarChartOptionsSmall"
                             class="pa-0 ma-0"></ApexRadarSingle>
        </div>
        <v-card-title class="title">{{ ca.company_name }}</v-card-title>
        <v-card-subtitle>{{ dataAggiornamento }} </v-card-subtitle>
        <v-card-subtitle>{{ ca.data.name }} {{ ca.name }}</v-card-subtitle>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions>
            <v-btn icon @click="dialogDelete = true">
                <v-icon>fas fa-trash</v-icon>
            </v-btn>
            <v-spacer/>
            <v-btn color="deep-purple lighten-2" text @click="goTo()">
                {{ $t('apri')}}
            </v-btn>
        </v-card-actions>
        <v-dialog v-model="dialogDelete" width="500">
            <v-card>
                <v-card-title>
                    {{ $t('conferma_eliminazione')}}
                </v-card-title>
                <v-card-subtitle>
                    {{ $t("messaggio_conferma_eliminazione")}}
                </v-card-subtitle>
                <v-card-actions>
                    <v-btn class="primary--text" text @click="dialogDelete = false">{{ $t('annulla')}}</v-btn>
                    <v-spacer/>
                    <v-btn class="warning--text" text @click="deleteElement()">{{ $t('procedi_eliminazione')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-card>
</template>

<script>
import ChartRadarSingle from '@/components/ChartRadarSingle.vue'
import ApexRadarSingle from '@/components/ApexChartRadarSingle.vue'
import graph_calcs from "@/helpers/graph_calcs";
import {getColor} from "@/helpers/get_color";
import {DateTime} from "luxon/build/es6/luxon";

export default {
    name: 'AuditCard',
    props: ['ca', 'index'],
    components: {
        ChartRadarSingle, ApexRadarSingle
    },
    data: function () {
        return {
            radarChartOptionsSmall: {
                legend: {
                    display: false
                },
            },
            dialogDelete: false
        }
    },
    computed: {
        dataAggiornamento: function(){
            let dt = DateTime.fromISO(this.ca.date);
            return dt.toFormat("dd/LL/yyyy");
        }
    },
    methods: {

        goTo() {
            let _item = this.ca
            this.$router.push({name: 'ManageAudit', params: {audit_id: _item.id}})
        },
        getColor(index, opacity) {
            let hex = getColor(index);
            const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`
            return setOpacity(hex, 0.5)

        },
        deleteElement() {
            this.$store.dispatch('remove', {
                kind: 'audit',
                data: this.ca,
                cb: () => {
                    this.$router.push('/explorer/audit');
                    this.dialogDelete = false;
                }
            })
        },

        loadRadarChart(ca) {
            const index = 0;
            const {score, dd, radarDataForAudit} = graph_calcs(ca);
            this.score = score;
            radarDataForAudit.datasets[0] = {
                label: ca.data.name,
                data: dd,
                borderColor: this.getColor(index),
                backgroundColor: this.getColor(index),
            }
            return radarDataForAudit;
        },

    }
}
</script>
