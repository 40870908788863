//****************************************************************************************************************************
// ATTENZIONE: questo file è in server/src/langs il file in webapp è un link
//****************************************************************************************************************************


export default {
    analisi:                            "Analyse",
    confronto:                          "Vergleich",
    confronta_audit:                    "Audits/Assessments vergleichen",
    benchmark:                          "Benchmark",
    compara_azienda:                    "Vergleich des Unternehmens mit der Zielgruppe",
    audit_assessment:                   "AUDIT/ASSESSMENT",
    audits:                             "Audits ",
    elenco_audit:                       "Liste der Audits/Assessments",
    crea_modifica:                      "Erstellen und bearbeiten",
    modelli:                            "Vorlagen",
    audit_template:                     "Audit/Assessment template ",
    aziende:                            "Unternehmen",
    azienda:                            "Unternehmen",
    elenco_aziende:                     "Firmenverzeichnis",
    apri:                               "Öffnen",
    conferma_eliminazione:              "Bestätigen Sie die Löschung",
    messaggio_conferma_eliminazione:    "Wollen Sie diesen Eintrag wirklich löschen? Der Vorgang kann nicht rückgängig gemacht werden",
    annulla:                            "Rückgängig",
    procedi_eliminazione:               "Mit der Löschung fortfahren",
    sections:                           "Abschnitte",
    confronta_title:                    "MEHRERE VERSIONEN DES AUDIT/ASSESSMENT VERGLEICHEN ",
    inizia_confronto:                   "Vergleich starten",
    nuovo:                              "Neu",
    seleziona_versione_confronto:       "Zu vergleichende Versionen auswählen",
    seleziona:                          "Wählen Sie",
    drop_to_upload:                     "Ziehen Sie eine Datei, um sie hochzuladen",
    clicca_per_selez_file:              "Klicken Sie, um Dateien auszuwählen",
    start_upload:                       "Hochladen starten",
    stop_upload:                        "Hochladen stoppen",
    audit_from_date:                    "Audit/Assessment von ",
    export:                             "Exportieren",
    esporta_formato:                    "Format exportieren",
    save:                               "Speichern",
    seleziona_paesi:                    "Länder auswählen",
    resport_saved:                      "Bericht gespeichert",
    selezionare_azienda:                "Wählen Sie ein Unternehmen:",
    filtro_settore:                     "SEKTOR",
    filtro_dipendenti:                  "BESCHÄFTIGTE",
    filtro_fatturato:                   "UMSATZ",
    filtro_anno_assessment:             "JAHR DES ASSESSMENT",
    regioni:                            "REGIONEN",
    province:                           "PROVINZEN",
    aggiorna:                           "Aktualisieren",
    nome:                               "Name",
    regione:                            "REGION",
    provincia:                          "PROVINZ",
    aziende_trovate:                    "Gefundene Unternehmen",
    tutti:                              "Alle",
    anno_in_corso:                      "Laufendes Jahr",
    anno_precedente:                    "Vorjahr",
    due_anni_precedenti:                "Vorherige zwei Jahre",
    media_benchmark:                    "BENCHMARK-DURCHSCHNITT",
    cerca:                              "Suche",
    crea_nuovo:                         "Neu erstellen",
    nessun_elemento:                    "Es gibt keine Datensätze {  kind } Erstellen Sie einen neuen Datensatz",
    nessun_record:                      "Es gibt keine Datensätze { kind } beginnen Sie mit der Erstellung eines Datensatzes",
    login:                              "Anmeldung",
    username:                           "Benutzername",
    password:                           "Kennwort",
    auth_error:                         "Fehler bei der Authentifizierung",
    versioni:                           "Versionen",
    grafico:                            "Grafik ",
    documenti:                          "Dokumente",
    report:                             "Bericht",
    modello_audit:                      "Audit-/Assessmentvorlage ",
    date_format:                        "Datum JJ-MM-TT",
    risposta:                           "Antwort",
    risposte:                           "Antworten",
    note:                               "Anmerkungen",
    nota:                               "Hinweis",
    adeguamento_punteggio:              "Punkteanpassung ",
    precedente:                         "Vorherige",
    prossimo:                           "Nächste",
    save_chart:                         "Grafik speichern",
    audit_saved:                        "Audit gespeichert",
    modifica_modello_del:               "Bearbeiten Sie die Auditvorlage der",
    versione:                           "Version",
    aggiungi_prima_sezione:             "Ersten Abschnitt hinzufügen",
    aggiungi_domanda:                   "Frage hinzufügen",
    aggiungi_sezione:                   "Abschnitt hinzufügen",
    inserisci_a:                        "Einfügen einer",
    id_sezione:                         "Abschnitts-ID",
    nome_sezione:                       "Name des Abschnitts",
    inserisci_alla_posizione:           "Zur Position hinzufügen",
    aggiungi:                           "Hinzufügen",
    crea:                               "Erstelle ",
    importa_dati:                       "Daten importieren",
    inserisci_azienda:                  "Neue Firma einfügen",
    denominazione:                      "Name / Firmenbezeichnung",
    url_immagine:                       "Bild-URL",
    via:                                "Straße",
    citta:                              "Stadt",
    cap:                                "Postleitzahl",
    nazione:                            "Land",
    nazioni:                            "Länder",
    piva:                               "MwSt.-Nr.",
    telefono:                           "Telefon",
    email:                              "E-Mail",
    sito_web:                           "Website",
    rap_legale:                         "Gesetzlicher Vertreter",
    avvio_attivita:                     "Gründung des Unternehmens",
    n_dipendenti:                       "Anzahl der Mitarbeiter",
    anno_rilevamento_dip:               "Jahr der Übernahme Anzahl der Beschäftigten",
    ultimo_fatturato:                   "Letzter Umsatz",
    anno_rilevamento_fatturato:         "Jahr der Erhebung des letzten Umsatzes",
    codice_ateco:                       "ATECO-Code / Tätikeitskodex",
    codice_nace:                        "NACE-Code / Tätikeitskodex",
    altre_sedi:                         "Andere Standorte",
    struttura_societaria:               "Unternehmensstruktur",
    ha_il_cda:                          "Haben Sie einen Verwaltungsrat",
    cda:                                "Verwaltungsrat",
    ha_comitato_scientifico:            "Verfügt das Unternehmen über einen wissenschaftlichen Ausschuss?",
    comitato_scientifico:               "Wissenschaftlicher Ausschuss",
    iscrizione_albo_imp_artigiane:      "Eintragung in das Register für Handwerksbetriebe",
    iscrizione_pmi_innovative:          "Eintragung von innovativen KMU",
    data_pmi_innovative:                "Eintragungsdatum innovative KMU",
    iscrizione_startup_innovative:      "Eintragung innovative Start-up-Unternehmen",
    data_iscrizione_startup_innovative: "Datum der Eintragung innovatives Start-up-Unternehmen",
    is_pmi:                             "Ist es ein KMU?",
    is_startup:                         "Handelt es sich um ein Start-up oder ein Spin-off?",
    tipo_di_spinoff:                    "Art des Ablegers",
    spinoff_da:                         "Spin-off abgeleitet von",
    partecipazione_altre_scocieta:      "Beteiligung an anderen Unternehmen",
    elenco_altr_partecipate:            "Liste anderer beteiligter Unternehmen",
    cerca_azienda_crm:                  "Unternehmen im CRM suchen",
    importa:                            "importieren",
    info_azienda:                       "Informationen zum Unternehmen",
    documenti_azienda:                  "Dokumente des Unternehmens",
    elimina:                            "löschen",
    nessun_audit:                       "Keine Audits",
    nessun_audit_desc:                  "Es gibt keine Audits für das Unternehmen { company_name }, bitte beginnen Sie mit der Erstellung eines Audits.",
    crea_audit:                         "Audit erstellen",
    errore_salvataggio:                 "Fehler beim Speichern",
    confronto_intro:                    "Vergleichen Sie die Rangfolge der Unternehmen in den 7 Umfragebereichen, indem Sie zwei oder mehr Audits/Assessments aus der Liste auswählen",
    seleziona_hint:                     "Audits/Assessments zum Vergleich auswählen",
    ultimo_aggiornamento:               "Letzte Aktualisierung",
    autore:                             "Autor",
    dati_generali:                      "Allgemeine Daten",
    contatti:                           "Kontakt",
    altre_info:                         "Weitere Informationen",
    yes:                                "Ja",
    no:                                 "Nein",
    data:                               "Datum",
    referente:                          "Kontaktperson",
    sezione:                            "Abschnitt",
    domanda:                            "Frage",
    punti:                              "Punkte",
    risposta_testuale:                  "Textliche Antwort",
    premessa:                           "Vorwort",
    descrizione_azienda:                "Beschreibung des Unternehmens",
    paesi_dove_esporta:                 "Länder, in denen das Unternehmen seine Produkte vertreibt",
    situazione_economica:               "Wirtschaftliche und finanzielle Lage",
    risultati_audit:                    "Audit-Ergebnisse",
    prodotto:                           "Produkt",
    mercati:                            "Märkte",
    strategie_export:                   "Exportstrategien",
    risorse_umane:                      "Personalressourcen",
    processi:                           "Prozesse",
    export_digitale:                    "Digitaler Export",
    comunicazione_promozione:           "Exportkommunikation/Promotion",
    sintesi_assessment:                 "Zusammenfassung der Bewertung",
    punti_forza:                        "Stärken",
    ambiti_miglioramento:               "Verbesserungswürdige Bereiche",
    proposta_attivita:                  "Vorgeschlagene Aktivitäten",
    indicatori:                         "Indikatoren",
    indice_redditivita:                 "Rentabilität (EBITDA/UMSATZ)",
    indice_solidita:                    "Bilanzstärke  (NETTOFINANZLAGE/EIGENKAPITAL)",
    indice_equilibrio:                  "Wirtschaftliches und finanzielles Gleichgewicht (NETTOFINANZLAGE/EBITDA) ",
    indice_indipendenza:                "Finanzielle Unabhängigkeit (EIGENKAPITAL/GESAMTE AKTIVA)",
    utile:                              "GEWINN",
    mol:                                "EBITDA",
    ricavi:                             "ERTRÄGE",
    di:                                 "von",
    anni:                               "Jahre",
    spiegazione_mol:                    "EBITDA",
    spiegazione_ricevi:                 "UMSATZ",
    spiegazione_posizione_finanziaria:  "NETTOFINANZLAGE",
    spiegazione_patrimonio:             "EIGENKAPITAL",
    spiegazione_totale_attivo:          "GESAMTE AKTIVA ",
    spiegazione_utile:                  "GEWINN DES GESCHÄFTSJAHRES ",
    assessor:                           "Assessor",
    audit_salvato:                      "Audit gespeichert",
    dati_salvati:                       "Daten gespeichert",
}
